import axios from "axios";
import { ExchangeSchema } from "../redux/actions";

export interface SpentWithSponsorshipBody {
  r: string;
  v: number;
  s: string;
  user: string;
  resource: string;
}

export const spentWithSponsorship = (body: any) => {
  return axios
    .post(
      `${process.env.REACT_APP_API_URL}/clients/buy-burning-item`,
      body
    )
    .then((res: any) => {
      return { ...res.data };
    })
    .catch((err) => err?.response);
};

export const exchangeFWDtoUSDT = (body: ExchangeSchema) => {
  return axios
    .post(
      `${process.env.REACT_APP_API_URL}/clients/exchange-tokens`,
      body
    )
    .then((res: any) => {
      return res.data;
    })
};
