import React from "react";
import {
  Button,
  ButtonSize,
  ButtonType,
} from "../../../shared/components/Button";
import styled from "styled-components";
import { Color } from "../../../constants/ColorEnum";
import { IconType } from "../../../constants/IconType";
import { useCustomTranslation } from "../../locale/hooks/useCustomTranslation";
import { useNavigateWithSearchParams } from "../../../shared/hooks/useNavigateWithSearchParams";
import { RoutesTypes } from "../../../constants/RoutesTypes";

const HistoryWrap = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 4px;
`;

const ButtonHistory = styled(Button)`
  width: auto;
  background-color: ${Color.SECONDARY05};
  border: none;
  padding: 0 8px;

  span {
    margin: 0;
  }
`;

const ButtonSettings = styled(Button)`
  background-color: ${Color.SECONDARY05};
  border: none;
  width: auto;
  min-width: 123px;
  padding: 0 8px;

  span {
    margin: 0;
  }

  svg path {
    fill: none;
  }
`;

export const HistoryLine = () => {
  const { t } = useCustomTranslation();
  const { navigateWithSearchParams } = useNavigateWithSearchParams();

  return (
    <HistoryWrap>
      <ButtonSettings
        onClick={() => {
          navigateWithSearchParams({}, RoutesTypes.walletSettings);
        }}
        distance={"4px"}
        buttonType={ButtonType.EMPTY}
        size={ButtonSize.M}
        icon={IconType.Walletsettings}
      >
        {t("walletSettings")}
      </ButtonSettings>
      <ButtonHistory
        onClick={() => {
          navigateWithSearchParams({}, RoutesTypes.historyCashbackFirst);
        }}
        distance={"4px"}
        buttonType={ButtonType.EMPTY}
        size={ButtonSize.M}
        icon={IconType.history}
      >
        {t("history")}
      </ButtonHistory>
    </HistoryWrap>
  );
};
