import React, { useState } from "react";
import styled from "styled-components";
import { Color } from "../../constants/ColorEnum";
import { TextSize } from "../../constants/TextSize";
import { TextWeight } from "../../constants/TextWeight";

import { Text } from "./Text";
import { useCustomTranslation } from "../../features/locale/hooks/useCustomTranslation";
import { Icon } from "./Icon";
import { IconType } from "../../constants/IconType";
import { Button, ButtonSize, ButtonType } from "./Button";
import { useWalletConnectClient } from "../../context/ClientContext";
import { requestToChangeChain2 } from "../../utils/utils";
import MM from "../../resources/images/mm.png";

export type ActionRequeredCBProps = {
  fullWidth?: boolean;

  height?: string;
};

const Wrapper = styled.div<{ fullWidth?: boolean; height?: string }>`
  width: ${({ fullWidth }) => (fullWidth ? "100%" : "calc(100% - 16px)")};
  height: ${({ height }) => (height ? `${height}` : "auto")};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  box-sizing: border-box;
`;

const CoinbaseLogo = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 40px;
`;

const IconStyled = styled(Icon)`
  svg path {
    fill: ${Color.SECONDARY};
  }
`;

const Title = styled(Text)`
  margin-bottom: 8px;
`;

const TextStyled = styled(Text)`
  margin: 0 45px;
`;


const MetamaskAction = styled.div`
  display: flex;
  flex-direction: column;

  border-radius: 8px;
  background-color: ${Color.SECONDARY08};

  padding: 10px;
  position: absolute;
  bottom: 80px;
  width: calc(100% - 36px);
`;

const FirstLine = styled.div`
  display: flex;
  margin-bottom: 12px;
`;

const TextWrapper = styled.div`
  width: calc(100% - 45px)
  margin-left: 8px;
  text-align: left;
  margin-left: 8px;
`;

const ImageStyled = styled.img<{ minHeight: string }>`
  border-radius: 8px 8px 0 0;
  min-height: ${({ minHeight }) => minHeight};
`;

export function ActionRequiredMetamask({ fullWidth, height }: ActionRequeredCBProps) {
  const { client, session } = useWalletConnectClient();
  const { t } = useCustomTranslation();

  const [buttonDisabled, setButtonDisabled] = useState(false);


  return (
    <Wrapper fullWidth={fullWidth} height={height}>
      <CoinbaseLogo>
      <img
          src={MM}
          alt={t("Metamask")}
          width={32}
          height={32}
        />
        <IconStyled icon={IconType.metamask} size={152} sizeHeight={26} />
      </CoinbaseLogo>

      <Title
        weight={TextWeight.BOLD}
        size={TextSize.XXL}
        color={Color.SECONDARY}
      >
        {t("actionRequired")}
      </Title>
      <TextStyled
        size={TextSize.M}
        weight={TextWeight.REGULAR}
        color={Color.SECONDARY}
      >
        {t("confirmMetaMask")}
      </TextStyled>

      <MetamaskAction>
            <FirstLine>
              <ImageStyled
                src={MM}
                alt="Metamask"
                width={40}
                minHeight={"40px"}
              />
              <TextWrapper>
                <Text
                  size={TextSize.XS}
                  weight={TextWeight.REGULAR}
                  color={Color.SECONDARY80}
                >
                  {t("ensure", {networkName: t("Polygon")}).replace(/"/g, '')}
                </Text>
                {/* {" "}
                <Text
                  size={TextSize.XS}
                  weight={TextWeight.BOLD}
                  color={Color.SECONDARY80}
                >
                  {t("polygonNetwork")}
                </Text>{" "}
                <Text
                  size={TextSize.XS}
                  weight={TextWeight.REGULAR}
                  color={Color.SECONDARY80}
                >
                  {t("whileUsingMetaMask")}
                </Text> */}
              </TextWrapper>
            </FirstLine>
            <Button
              buttonType={ButtonType.EMPTY}
              size={ButtonSize.M}
              onClick={() => {
                setButtonDisabled(true);
                setTimeout(() => setButtonDisabled(false), 10000);
                requestToChangeChain2(client, session?.topic);
              }}
              fullWidth={true}
              disabled={buttonDisabled}
            >
              <Text
                size={TextSize.M}
                color={Color.SECONDARY}
                weight={TextWeight.BOLD}
              >
                {t("switchNetwork")}
              </Text>
            </Button>
          </MetamaskAction>
    </Wrapper>
  );
}
