export enum IconType {
  rocket = "rocket",
  settings = "settings",
  close = "close",
  success = "success",
  successSmall="successSmall",
  loader = "loader",
  error = "error",
  arrow = "arrow",
  shevron = "shevron",
  lightning = "lightning",
  visible = "visible",
  visibleNot = "visibleNot",
  cross = "cross",
  checkmarkPartial = "checkmarkPartial",
  checkmarkChecked = "checkmarkChecked",
  editCancel = 'editCancel',
  editPencil = 'editPencil',
  editSubmit = 'editSubmit',
  chevronDown = 'chevronDown',
  wallet = 'wallet',
  question = 'question',
  rewind = 'rewind',
  domino = 'domino',
  lock = 'lock',
  arrows = 'arrows',
  shevron_next = 'shevron_next',
  back = 'back',
  copy = 'copy',
  copy2 = 'copy2',
  lockExchange = 'lockExchange',
  increase = 'increase',
  claimPlus = 'claimPlus',
  history = 'history',
  warning = 'warning',
  exclamation = 'exclamation',
  walletConnect = 'walletConnect',
  chevronLeft = 'chevronLeft',
  chevronRight = 'chevronRight',
  coinbase = 'coinbase',
  zerion = 'zerion',
  trust = 'trust',
  metamask = 'metamask',
  Walletsettings = 'Walletsettings',
}
