import React from "react";
import styled from "styled-components";
import { Color } from "../../../constants/ColorEnum";
import { RoutesTypes } from "../../../constants/RoutesTypes";
import { TextSize } from "../../../constants/TextSize";
import { TextWeight } from "../../../constants/TextWeight";
import CoinbaseWallet from "../../../resources/images/CoinbaseWallet.png";
import {
  Button,
  ButtonSize,
  ButtonType,
} from "../../../shared/components/Button";
import { Text } from "../../../shared/components/Text";
import { useNavigateWithSearchParams } from "../../../shared/hooks/useNavigateWithSearchParams";
import { useQueryParams } from "../../../utils/utils";
import { useCustomTranslation } from "../../locale/hooks/useCustomTranslation";
import { IconType } from "../../../constants/IconType";
import { Icon } from "../../../shared/components/Icon";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  height: 100%;
  padding: 0 12px;
`;

const ButtonWithMargin = styled(Button)`
  margin-top: 16px;
`;

const CoinbaseLogo = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 40px;
`;

const IconStyled = styled(Icon)`
  svg path {
    fill: ${Color.SECONDARY};
  }
  margin-left: 13px;
`;

const CenterBlock = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: 120px;
  width: calc(100% - 12px)
`;

const Title = styled(Text)`
  margin-bottom: 8px;
`;

const TextStyled = styled(Text)`
  margin: 0 14px;
`;

interface CoinbaseINcorrectAddressProps {
  toggleCoinbaseReconnect: () => void
}

export const CoinbaseIncorrectAddress = ({toggleCoinbaseReconnect}: CoinbaseINcorrectAddressProps) => {
  const { isDublicate } = useQueryParams();
  const { navigateWithSearchParams } = useNavigateWithSearchParams();
  const { t } = useCustomTranslation();

  return (
    <CenterBlock>
      <CoinbaseLogo>
        <img
          src={CoinbaseWallet}
          alt={t("coinbaseWallet")}
          width={32}
          height={32}
        />
        <IconStyled icon={IconType.coinbase} size={152} sizeHeight={26} />
      </CoinbaseLogo>

      <Title
        weight={TextWeight.BOLD}
        size={TextSize.XXL}
        color={Color.SECONDARY}
      >
        {isDublicate === "true"
          ? t("addressAlreadyInUse")
          : t("incorrectAddress")}
      </Title>
      <TextStyled
        size={TextSize.M}
        weight={TextWeight.REGULAR}
        color={Color.SECONDARY}
      >
        {isDublicate === "true"
          ? t("addressAlreadyInUseText")
          : t("incorrectAddressText")}
      </TextStyled>

      <ButtonWithMargin
        onClick={() => {
          toggleCoinbaseReconnect();
        }}
        size={ButtonSize.XL}
        fullWidth={true}
      >
        <Text
          size={TextSize.L}
          color={Color.SECONDARY}
          weight={TextWeight.BOLD}
        >
          {t("reconnect")}
        </Text>
      </ButtonWithMargin>
      <ButtonWithMargin
        buttonType={ButtonType.WHITE_BORDER}
        size={ButtonSize.XL}
        onClick={() => navigateWithSearchParams({}, RoutesTypes.dashboard)}
        fullWidth={true}
      >
        <Text
          size={TextSize.L}
          color={Color.SECONDARY}
          weight={TextWeight.BOLD}
        >
          {t("backToWidget")}
        </Text>
      </ButtonWithMargin>
    </CenterBlock>
  );
};
