import React, { useEffect, useMemo, useRef } from "react";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import { RoutesTypes } from "../../../constants/RoutesTypes";
import { useQueryParams } from "../../../utils/utils";
import { AppLoading } from "../../../shared/components/AppLoader";
import { Color } from "../../../constants/ColorEnum";
import { LangEnum } from "../../locale/redux/locale.slice";
import { useNavigateWithSearchParams } from "../../../shared/hooks/useNavigateWithSearchParams";
import axios, { AxiosError } from "axios";

const ProgressContainer = styled.div`
  margin: auto;
  border-radius: 8px;
  width: 400px;
  height: 100vh;
  min-height: 640px;
  padding-top: 30px;
  background-color: ${Color.BACKGROUND_COLOR};
`;

const FlexCenter = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  height: 100%;
`;

type QueryParams = {
  sid?: string;
  isMobile?: boolean;
  lang?: string;
  helpLink?: string;
  foreignId?: number;
  callback?: string;
  walletConnectionType?: string;
};

const AuthForm: React.FC = React.memo(() => {
  const { sid, isMobile, lang, helpLink, foreignId, callback, walletConnectionType } = useQueryParams();
  const { navigateWithSearchParams } = useNavigateWithSearchParams();
  const navigate = useNavigate();
  
  const interceptorRef = useRef<number | null>(null);

  useEffect(() => {
    if (interceptorRef.current === null) {
      const interceptorId = axios.interceptors.response.use(
        (response) => response,
        (error: AxiosError) => {
          //@ts-ignore
          if (error.response && error.response.data && (error.response.data.message === "Session ID has not found" || error.response.data.status === 401)) {
            navigateWithSearchParams({}, RoutesTypes.authError);
          }
          return Promise.reject(error);
        }
      );
      interceptorRef.current = interceptorId;
    }

    return () => {
      if (interceptorRef.current !== null) {
        axios.interceptors.response.eject(interceptorRef.current);
        interceptorRef.current = null;
      }
    };
  }, [navigateWithSearchParams]);

  const currentUrl = useMemo(() => {
    const queryParams: QueryParams = {};
  
    if (sid !== undefined) queryParams.sid = sid;
    if (isMobile !== undefined) queryParams.isMobile = isMobile;
    if (lang !== undefined) queryParams.lang = lang || LangEnum.en;
    if (helpLink !== undefined) queryParams.helpLink = helpLink;
    if (foreignId !== undefined) queryParams.foreignId = foreignId;
    if (callback !== undefined && callback !== 'null') queryParams.callback = callback;
    if (walletConnectionType !== undefined && walletConnectionType !== 'null') queryParams.walletConnectionType = walletConnectionType;
    
    //@ts-ignore
    const queryString = new URLSearchParams(queryParams).toString();
    return `${RoutesTypes.dashboard}?${queryString}`;
  }, [sid, isMobile, lang, helpLink, foreignId, callback, walletConnectionType]);

  useEffect(() => {
    if (sid) {
      navigate(currentUrl, { replace: true });
    }
  }, [sid, currentUrl, navigate]);

  if (sid) {
    return null;
  } else {
    return (
      <ProgressContainer>
        <FlexCenter>
          <AppLoading message={"Getting Data"} fullHeight={true} />
        </FlexCenter>
        {/* <LoadingText>Waiting for initialization</LoadingText> */}
      </ProgressContainer>
    );
  }
});

export { AuthForm };
