import React, { ReactNode, useMemo } from "react";
import styled from "styled-components";
import { Color } from "../../constants/ColorEnum";
import { TextSize } from "../../constants/TextSize";
import { TextWeight } from "../../constants/TextWeight";
import { Icon } from "./Icon";
import { Text } from "./Text";
import WalletConnectBg from "../../resources/images/WalletConnectBg.png";
import { IconType } from "../../constants/IconType";
import { Button, ButtonSize } from "./Button";
import { requestToChangeChain2 } from "../../utils/utils";
import { useWalletConnectClient } from "../../context/ClientContext";

export type ActionRequeredProps = {
  actionMessage: string;
  extraMessage: string;
  code?: string;
  children?: ReactNode;
  fullWidth?: boolean;
  smallImage?: boolean;
  image?: string;
  imageSize?: number;
  height?: string;
  withWalletConnect?: boolean;
  callback?: () => void;
};

const Wrapper = styled.div<{ fullWidth?: boolean; height?: string }>`
  width: ${({ fullWidth }) => (fullWidth ? "100%" : "calc(100% - 16px)")};
  height: ${({ height }) => (height ? `${height}` : "auto")};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  box-sizing: border-box;
`;

const TextStyled = styled(Text)`
  margin-top: 16px;
`;

const ImageStyled = styled.img<{ minHeight: string }>`
  border-radius: 8px 8px 0 0;
  min-height: ${({ minHeight }) => minHeight};
`;

const ImageWrapper = styled.div`
  position: relative;
`;

const TextExtra = styled(Text)`
  width: 336px;
  margin: 8px 0;
`;

const IconAbsolute = styled(Icon)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

export function ActionRequered({
  actionMessage,
  extraMessage,
  children,
  fullWidth,
  smallImage,
  image,
  imageSize,
  height,
  withWalletConnect,
}: ActionRequeredProps) {
  const calcImageSize = useMemo(
    () => (smallImage ? 90 : imageSize ? imageSize : 212),
    []
  );
  const { client, session } = useWalletConnectClient();

  return (
    <Wrapper fullWidth={fullWidth} height={height}>
      <ImageWrapper>
        <ImageStyled
          src={image || WalletConnectBg}
          alt="Metamask"
          width={calcImageSize}
          minHeight={image ? "50px" : "201px"}
        />
        {!image && (
          <IconAbsolute
            icon={IconType.walletConnect}
            size={112}
            color={Color.SECONDARY}
          />
        )}
      </ImageWrapper>
      <TextStyled
        size={TextSize.XXL}
        weight={TextWeight.BOLD}
        color={Color.SECONDARY}
      >
        {actionMessage}
      </TextStyled>
      <TextExtra size={TextSize.M} color={Color.SECONDARY}>
        {extraMessage}
      </TextExtra>

      {withWalletConnect && (
        <>
          <Button
            size={ButtonSize.S}
            onClick={() => {
              requestToChangeChain2(client, session?.topic);
            }}
            fullWidth={true}
          >
            <Text
              size={TextSize.L}
              color={Color.SECONDARY}
              weight={TextWeight.BOLD}
            >
              Switchnetwork
            </Text>
          </Button>
        </>
      )}
      {children}
    </Wrapper>
  );
}
