import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { RoutesTypes } from "../../../constants/RoutesTypes";
import { Color } from "../../../constants/ColorEnum";
import { IconType } from "../../../constants/IconType";
import { Icon } from "../../../shared/components/Icon";
import { Text } from "../../../shared/components/Text";
import { TextSize } from "../../../constants/TextSize";
import { TextWeight } from "../../../constants/TextWeight";
import { useNavigateWithSearchParams } from "../../../shared/hooks/useNavigateWithSearchParams";
import { useQueryParams } from "../../../utils/utils";

const IconWrapper = styled.div<{ isActive: boolean }>`
  width: 56px;
  height: 56px;
  background-color: ${({ isActive }) =>
    isActive ? Color.NAVIGATION_CIRCLE : Color.NAVIGATION_CIRCLE_INACTIVE};
  border-radius: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Wrapper = styled.div<{ isActive?: boolean }>`
  position: relative;
  box-sizing: border-box;
  background-color: ${Color.NAVIGATION_WRAPPER};
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 16px 8px;
  gap: 16px;
  width: 100%;
  height: 88px;
  margin-top: 4px;
  background: ${Color.NAVIGATION_WRAPPER};
  border-radius: 8px;
  cursor: ${({ isActive }) => (isActive ? "pointer" : "auto")};
  &:hover {
    background-color: ${({ isActive }) =>
      isActive ? Color.NAVIGATION_WRAPPER_HOVER : Color.NAVIGATION_WRAPPER};
  }

  &:hover {
    ${IconWrapper} {
      background-color: ${({ isActive }) =>
        isActive
          ? Color.NAVIGATION_CIRCLE_HOVER
          : Color.NAVIGATION_CIRCLE_INACTIVE};
    }
  }
`;

const ActionBlock = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  right: 8px;
`;

const InfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
`;

const StyledIcon = styled(Icon)`
  svg {
    path {
      fill: none;
    }
  }
`;

const StyledIcon2 = styled(Icon)`
  svg {
    opacity: 0.5;
  }
`;

const StyledIcon3 = styled(Icon)`
  svg {
    opacity: 0.5;
    fill: none;

    path {
      fill: none;
    }
    ${Wrapper}:hover & {
      opacity: 1;
  }
`;

const StyledIcon4 = styled(Icon)`
  svg {
    opacity: 0.5;
    fill: none;

    path {
      fill: none;
    }
`;

const TextSub = styled(Text)`
  text-align: left;
  max-width: calc(100% - 30px);
`;

interface NavigationLinkProps {
  isActive: boolean;
  icon: IconType;
  title: string;
  subTitle: string;
  link: RoutesTypes;
  activeTag?: string;
}

export function NavigationLink({
  isActive,
  icon,
  title,
  subTitle,
  link,
  activeTag,
}: NavigationLinkProps) {
  const { isMobile } = useQueryParams();
  const { navigateWithSearchParams } = useNavigateWithSearchParams();
  const [mobileHover, setMobileHover] = useState(false);

  return (
    <Wrapper
      isActive={isActive || mobileHover}
      onClick={() => {
        if (isActive) {
          if (isMobile === "true") {
            setMobileHover(true);
            setTimeout(() => {

              navigateWithSearchParams({ activeTag }, link);
            }, Number(process.env.REACT_APP_MOBILE_HOVER_DELAY))
          } else {
            navigateWithSearchParams({ activeTag }, link);
          }
        }
      }}
    >
      <IconWrapper isActive={isActive}>
        {isActive ? (
          <StyledIcon3
            icon={icon}
            size={56}
            color={Color.SECONDARY}
          ></StyledIcon3>
        ) : (
          <StyledIcon4
            icon={icon}
            size={56}
            color={Color.SECONDARY}
          ></StyledIcon4>
        )}
      </IconWrapper>
      <InfoWrapper>
        <Text
          size={TextSize.L}
          weight={TextWeight.BOLD}
          color={isActive ? Color.SECONDARY : Color.SECONDARY40}
        >
          {title}
        </Text>
        <TextSub
          size={TextSize.XS}
          weight={TextWeight.REGULAR}
          color={isActive ? Color.SECONDARY60 : Color.SECONDARY40}
        >
          {subTitle}
        </TextSub>
      </InfoWrapper>
      <ActionBlock>
        {/* <StyledIcon
          icon={IconType.lock}
          size={15}
          color={Color.SECONDARY}
        ></StyledIcon> */}
        {isActive ? (
          <StyledIcon
            icon={IconType.shevron_next}
            size={15}
            color={Color.SECONDARY}
          ></StyledIcon>
        ) : (
          <StyledIcon2
            icon={IconType.lock}
            size={24}
            color={Color.SECONDARY}
          ></StyledIcon2>
        )}
      </ActionBlock>
    </Wrapper>
  );
}
