export enum RoutesTypes {
  home = "/",

  buy_bonuses = '/buy_bonuses',
  buy_bonuses_item = '/buy_bonuses/:id',
  exchange = '/exchange',
  howItWorks = '/how_it_works',
  mobileMetamask = '/go_to_metamask_mobile',
  mobileCoinbase = '/go_to_coinbase_mobile',
  SwitchNetwork = '/switch_network',
  switchWallet = '/switch_wallet',
  installMetamask = '/install_metamask',
  quickExchange = '/quick_exchange',

  resources = "/resources",
  dashboard = "/dashboard",
  cashback = "/cashback",
  burning_items = "/burning-items",
  grades = "/grades",
  failed_events="/failed-events",
  webhooks = "/webhooks",
  auth = "",

  app = "/app",
  modal = "/app/modal",
  modal_bonus = "/app/modal/bonus-list",
  modal_bonus_terms = "/app/modal/bonus-terms",
  modal_bonus_loading = "/app/modal/bonus-loading",
  modal_bonus_success = "/app/modal/bonus-success",
  iframe = "/app/iframe",
  carousel = `/app/iframe/carousel`,
  info = "/app/iframe/info",
  connect = "/app/iframe/connect",
  convert = "/app/iframe/convert",
  error = "/app/iframe/error",
  mobile_bonus = "/app/iframe/mobile-bonus",

  history = '/history',
  historyCashback = '/history/cashback',
  historyCashbackFirst = '/history/cashback/first',
  historyCashbackSecond = '/history/cashback/second',
  historyCashbackSecondSimplified = '/history/cashback/second/simplified',
  historyCashbackSecondDetailed = '/history/cashback/second/detailed',
  historyCashbackThird = '/history/cashback/third',
  historyTokenBurn = '/history/token_burn',
  historyBonusPurchase = '/history/bonus_purchase',
  historyBonusPurchaseFirst = '/history/bonus_purchase/first',
  historyBonusPurchaseSecond = '/history/bonus_purchase/second',

  walletSettings='/walletsettings',
  authError="/authError",
}
