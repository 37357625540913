import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { BonusSchema } from "./bonus.slice";
import { Dispatch, SetStateAction } from "react";
import { ProcessStatus } from "../components/BuyBonuses";
import {
  exchangeFWDtoUSDT,
  spentWithSponsorship,
} from "../utils/spentWithSponsorship";
import { ProcessStatusExchange } from "../components/Exchange";
import { ethers, providers } from "ethers";
import { getNonces } from "../utils/getNonces";
import { LangEnum } from "../../locale/redux/locale.slice";


export interface GetBonusesShema {
  page?: string;
  limit?: string;
  sid: string;
  language: LangEnum;
}

export interface GetTagsShema {
  sid: string;
}

export interface ExchangeSchema {
  sid: string;
  r: string;
  v: number;
  s: string;
  amount: number;
  deadline: number;
}

export enum TxStatus {
  MINED = "mined",
  IN_PROGRESS = "in_progress",
  FAILED = "failed",
}

export const getBonuses = createAsyncThunk(
  "bonuses/getBonuses",
  async ({ page, limit, sid, language }: GetBonusesShema) => {
    const { data } = await axios.get(
      `${process.env.REACT_APP_API_URL}/clients/get-burning-items?limit=${limit}&page=${page}&sid=${sid}&language=${language}`
    );
    return data;
  }
);

export const getTags = createAsyncThunk(
  "bonuses/getTags",
  async ({ sid }: GetTagsShema) => {
    const { data } = await axios.get(
      `${process.env.REACT_APP_API_URL}/clients/get-tags-by-sid?sid=${sid}`
    );
    return data;
  }
);

export const getAmount = createAsyncThunk(
  "bonuses/getAmount",
  async ({ amount, deadline, sid }: { amount: number; deadline: Date; sid: string }) => {
    const { data } = await axios.get(
      `${process.env.REACT_APP_API_URL}/clients/calculate-exchange-tokens?amount=${amount}&deadline=${deadline}&sid=${sid}`
    );
    return data;
  }
);

export const exchangeTokens = createAsyncThunk(
  "bonuses/exchangeTokens",
  async (value: ExchangeSchema) => {
    const { data } = await axios.post(
      `${process.env.REACT_APP_API_URL}/clients/exchange-tokens`,
      value
    );
    return data;
  }
);

//https://app.rewindprotocol.com/api/v1/clients/get-tags-by-sid?sid=25c176ec-623b-4c42-a4f9-d128f5514daa'

export const getContractDetails = ({sid}: {sid: string}) => {
  return axios
    .get(`${process.env.REACT_APP_API_URL}/clients/get-contracts-data?sid=${sid}`)
    .then((res: any) => {
      return { ...res.data };
    });
};

export type OffchainPurchaseProps = {
  sid: string;
  resourceId: string;
}

export const buyBonusWithoutSign = (value: OffchainPurchaseProps) => {
  return axios
    .post(`${process.env.REACT_APP_API_URL}/offchain-purchase/buy`, value)
}

export const getPermitSignature = async (
  card: BonusSchema,
  setProcessStatus: Dispatch<SetStateAction<ProcessStatus>>,
  setHashCreated: any,
  tokenContractAddress: string,
  spender: string,
  amount: number,
  protocolName: string,
) => {
  //@ts-ignore
  const ethereum = window.ethereum;

  if (!ethereum) {
    setProcessStatus(ProcessStatus.ERROR);
    throw new Error("No crypto wallet found. Please install it.");
  }

  const accounts = await ethereum.request({
    method: "eth_requestAccounts",
  });
  //@ts-ignore
  let account = accounts[0];

  //hex
  const value = "0x" + (card.usdPrice * 10e17).toString(16).toUpperCase();

  // console.log(value, "VALUE");

  //hardcode
  // const deadline =
  //   "0xffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffff";
  const plusDay = Math.floor(Date.now() / 1000) + 86400;
  
  const deadline = ethers.utils.hexlify(plusDay);
  let nonce;

  try {
    nonce = await getNonces(account, tokenContractAddress);
  } catch (err) {
    //setProcessStatus(ProcessStatus.ERROR)
  }

  console.log(nonce, 'NONCE_OLD')

  const chainId = await ethereum.request({ method: "eth_chainId" });
  const ethersAmount = ethers.utils.parseEther(String(amount * 1.2)).toString();

  const message = {
    owner: account,
    spender: spender,
    value: ethersAmount,
    nonce,
    deadline,
  };

  const typedData = JSON.stringify({
    types: {
      EIP712Domain: [
        {
          name: "name",
          type: "string",
        },
        {
          name: "version",
          type: "string",
        },
        {
          name: "chainId",
          type: "uint256",
        },
        {
          name: "verifyingContract",
          type: "address",
        },
      ],
      Permit: [
        {
          name: "owner",
          type: "address",
        },
        {
          name: "spender",
          type: "address",
        },
        {
          name: "value",
          type: "uint256",
        },
        {
          name: "nonce",
          type: "uint256",
        },
        {
          name: "deadline",
          type: "uint256",
        },
      ],
    },
    primaryType: "Permit",
    domain: {
      //hard code
      name: protocolName,
      version: "1",
      chainId,
      verifyingContract: tokenContractAddress,
    },
    message: message,
  });

  await ethereum.sendAsync(
    {
      id: 2,
      method: "eth_signTypedData_v4",
      params: [account, typedData],

      from: account,
    },
    //@ts-ignore
    async function (err, result) {
      //@ts-ignore
      const resultString = result.result;
      if (resultString) {
        const r = resultString.slice(0, 66);
        const s = "0x" + resultString.slice(66, 130);
        const v = Number("0x" + resultString.slice(130, 132));

        setProcessStatus(ProcessStatus.LOADING);

        const res = await spentWithSponsorship({
          r,
          v,
          s,
          user: account,
          resource: card.foreignIdHash || "",
          amount: ethersAmount,
          deadline: plusDay,
        });

        if (res.hash) {
          setProcessStatus(ProcessStatus.WAITING_CONFIRMATION);
          setHashCreated(res.hash);
        } else {
          setProcessStatus(ProcessStatus.ERROR);
        }
      } else {
        setProcessStatus(ProcessStatus.CANCEL);
      }
    }
  );
};


// export const getPermitSignature = async (
//   walletConnectProvider: any,
//   card: BonusSchema,
//   setProcessStatus: Dispatch<SetStateAction<ProcessStatus>>,
//   setHashCreated: any,
//   tokenContractAddress: string,
//   spender: string,
//   amount: number,
//   protocolName: string,
//   account: string,
//   nonce: number
// ) => {
//   const plusDay = Math.floor(Date.now() / 1000) + 86400;

//   const deadline = ethers.utils.hexlify(plusDay);

//   const chainId = walletConnectProvider.chainId;

//   const ethersAmount = ethers.utils.parseEther(String(amount * 1.2)).toString();

//   const message = {
//     owner: account,
//     spender: spender,
//     value: ethersAmount,
//     nonce,
//     deadline,
//   };

//   const typedData = JSON.stringify({
//     types: {
//       EIP712Domain: [
//         {
//           name: "name",
//           type: "string",
//         },
//         {
//           name: "version",
//           type: "string",
//         },
//         {
//           name: "chainId",
//           type: "uint256",
//         },
//         {
//           name: "verifyingContract",
//           type: "address",
//         },
//       ],
//       Permit: [
//         {
//           name: "owner",
//           type: "address",
//         },
//         {
//           name: "spender",
//           type: "address",
//         },
//         {
//           name: "value",
//           type: "uint256",
//         },
//         {
//           name: "nonce",
//           type: "uint256",
//         },
//         {
//           name: "deadline",
//           type: "uint256",
//         },
//       ],
//     },
//     primaryType: "Permit",
//     domain: {
//       //hard code
//       name: protocolName,
//       version: "1",
//       chainId,
//       verifyingContract: tokenContractAddress,
//     },
//     message: message,
//   });

//   walletConnectProvider.sendAsync(
//     {
//       method: "eth_signTypedData_v4",
//       params: [account, typedData],
//     },
//     //@ts-ignore
//     async function (err, result) {
//       if (err) {
//         console.log(err, "ERROR");
//         setProcessStatus(ProcessStatus.CANCEL);
//       } else if (result) {
//         setProcessStatus(ProcessStatus.LOADING);
//         const resultString = result;
//         walletConnectProvider.disconnect();
//         if (resultString) {
//           const r = resultString.slice(0, 66);
//           const s = "0x" + resultString.slice(66, 130);
//           const v = Number("0x" + resultString.slice(130, 132));

//           console.log("STATUS_LOADING", r, s, v);
//           let res;
//           try {
//             res = await spentWithSponsorship({
//               r,
//               v,
//               s,
//               user: account,
//               resource: card.foreignIdHash || "",
//               amount: ethersAmount,
//               deadline: plusDay,
//             });
//           } catch (err) {
//             setProcessStatus(ProcessStatus.ERROR);
//           }

//           if (res.hash) {
//             setProcessStatus(ProcessStatus.WAITING_CONFIRMATION);
//             setHashCreated(res.hash);
//           }

//           // if (res) {
//           //   setProcessStatus(ProcessStatus.WAITING_CONFIRMATION);
//           //   setHashCreated(res);
//           // }
//         }
//       }
//     }
//   );
// };

export const getPermitSignatureForExchange = async (
  walletConnectProvider: any,
  setProcessStatus: Dispatch<SetStateAction<ProcessStatusExchange>>,
  setHashCreated: any,
  tokenContractAddress: string,
  spender: string,
  sid: string,
  amount: number,
  protocolName: string,
  account: string,
  nonce: number
) => {
  const plusDay = Math.floor(Date.now() / 1000) + 86400;

  const deadline = ethers.utils.hexlify(plusDay);

  // const provider = await EthereumProvider.init({
  //   projectId: projectId,
  //   chains: [Number(process.env.REACT_APP_CHAIN_ID)],
  //   showQrModal: true,
  //   methods: ["eth_signTypedData_v4"],
  // });

  const chainId = walletConnectProvider.chainId;
  console.log(amount, ethers.utils.parseEther(String(amount)).toString(), 'AMOUNT')

  const messageObj = {
    owner: account,
    spender: spender,
    value: ethers.utils.parseEther(String(amount)).toString(),
    nonce,
    deadline,
  };

  const typedData = {
    types: {
      // EIP712Domain: [
      //   {
      //     name: "name",
      //     type: "string",
      //   },
      //   {
      //     name: "version",
      //     type: "string",
      //   },
      //   {
      //     name: "chainId",
      //     type: "uint256",
      //   },
      //   {
      //     name: "verifyingContract",
      //     type: "address",
      //   },
      // ],
      Permit: [
        {
          name: "owner",
          type: "address",
        },
        {
          name: "spender",
          type: "address",
        },
        {
          name: "value",
          type: "uint256",
        },
        {
          name: "nonce",
          type: "uint256",
        },
        {
          name: "deadline",
          type: "uint256",
        },
      ],
    },
    primaryType: "Permit",
    domain: {
      name: protocolName,
      version: "1",
      chainId: 137,
      verifyingContract: tokenContractAddress,
    },
    message: messageObj,
  };

  const prov = new providers.Web3Provider(walletConnectProvider);
  const signer = prov.getSigner(account);
  const signature = await signer._signTypedData(typedData.domain, typedData.types, typedData.message)

      const { r, s, v } = ethers.utils.splitSignature(signature);
      console.log(r, s, v, "r, s, v");

      let res;
      try {
        res = await exchangeFWDtoUSDT({
          r,
          v: v,
          s,
          amount,
          sid,
          deadline: plusDay,
        });
      } catch (err) {
        setProcessStatus(ProcessStatusExchange.ERROR);
      }
  //   } catch (error) {
  //     console.error("Error signing typed data:", error);
  //   }
  // }

  // signTypedData();

  //     const prov = new providers.Web3Provider(walletConnectProvider);
  //     const signer = prov.getSigner(account);
  //     console.log("inside try_2");

  //     // Convert the message object to a JSON string
  // const messageJSON = JSON.stringify(messageObj);

  // // Convert the JSON string to a UTF-8 encoded array buffer
  // const messageBytes = ethers.utils.toUtf8Bytes(messageJSON);

  // // Get the hash of the message using keccak256 (you can also use sha256 if needed)
  // const messageHash = ethers.utils.keccak256(messageBytes);

  // // Convert the hash to a hexadecimal string representation
  // const hexMessage = ethers.utils.hexlify(messageHash);

  //     // try {
  //     //   console.log(ethers.utils.arrayify(getMessageHash(message)))
  //     // } catch (err) {
  //     //   console.log('ERR', err)
  //     // }
  //     const signature = await signer._signTypedData(typedData);
  //     console.log("inside try_ 3");
  // const { r, s, v } = ethers.utils.splitSignature(signature);
  // console.log(r, s, v, "r, s, v");

  // let res;
  // try {
  //   res = await exchangeFWDtoUSDT({
  //     r,
  //     v: v,
  //     s,
  //     amount,
  //     sid,
  //     deadline: plusDay,
  //   });
  // } catch (err) {
  //   setProcessStatus(ProcessStatusExchange.ERROR);
  // }
  //   try {
  //     await walletConnectProvider.sendAsync(
  //       {
  //         method: "eth_sign",
  //         params: [account, typedData],
  //       },
  // //@ts-ignore
  //       async function (err, result) {
  //         if (err) {
  //           console.log(err, 'ERRRR')
  //           setProcessStatus(ProcessStatusExchange.CANCEL);
  //         } else if (result) {
  //           setProcessStatus(ProcessStatusExchange.LOADING);
  //           console.log('walletConnectProvider.disconnect();')
  //           // walletConnectProvider.disconnect();
  //           const resultString = result;
  //           if (resultString) {
  //             const r = resultString.slice(0, 66);
  //             const s = "0x" + resultString.slice(66, 130);
  //             const v = Number("0x" + resultString.slice(130, 132));

  //             console.log("STATUS_LOADING", r, s, v);
  //             let res;
  //             try {
  //               res = await exchangeFWDtoUSDT({
  //                 r,
  //                 v: v,
  //                 s,
  //                 amount,
  //                 sid,
  //                 deadline: plusDay,
  //               });
  //             } catch (err) {
  //               setProcessStatus(ProcessStatusExchange.ERROR);
  //             }

  //             if (res) {
  //               setProcessStatus(ProcessStatusExchange.WAITING_CONFIRMATION);
  //               setHashCreated(res);
  //             }
  //           }
  //         }
  //       }
  //     );
  //   } catch (err) {
  //     setProcessStatus(ProcessStatusExchange.ERROR);
  //     console.log('walletConnectProvider.disconnect(); CATCH')
  //     // walletConnectProvider.disconnect();
  //   }
};

export const checkTxStatus = (txStatus: string, transactionTypeId?: number) => {
  if (transactionTypeId) {
    return axios
      .get(
        `${process.env.REACT_APP_API_URL}/clients/get-tx-status-synced?txHash=${txStatus}&transactionTypeId=${transactionTypeId}`
      )
      .then((res) => {
        return res.data;
      });
  } else {
    return axios
      .get(
        `${process.env.REACT_APP_API_URL}/clients/get-tx-status?txHash=${txStatus}`
      )
      .then((res) => {
        return res.data;
      });
  }
};
