import React, { useRef, useState } from "react";
import styled from "styled-components";
import { Color } from "../../../constants/ColorEnum";
import { Text } from "../../../shared/components/Text";
import { TextSize } from "../../../constants/TextSize";
import { TextWeight } from "../../../constants/TextWeight";
import {
  ScrollArea,
  ScrollAreaSize,
} from "../../../shared/components/ScrollArea";
import Scrollbars from "react-custom-scrollbars-2";
import { DefaultTagsEnum } from "../redux/bonus.slice";
import { useCustomTranslation } from "../../locale/hooks/useCustomTranslation";
import { Icon } from "../../../shared/components/Icon";
import { IconType } from "../../../constants/IconType";
import { useQueryParams } from "../../../utils/utils";

const TagsListWrapper = styled.div<{ isCovered?: boolean }>`
  width: ${({ isCovered }) => (isCovered ? "100%" : "100%")};
  position: relative;
  height: 40px;
`;

const ScrolAreaHideScroll = styled(ScrollArea)``;

const Wrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  height: 32px;
  margin-top: 8px;
  width: 100%;
  // overflow-x: scroll;
`;

const ArrowButtonLeft = styled.button`
  position: absolute;
  z-index: 100;
  left: 0px;
  top: 12px;
  background-color: rgba(32, 47, 64, 0.6);
  border: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  color: ${Color.SECONDARY};
  transition: background-color 0.3s ease;

  &:hover {
    background-color: ${Color.TAG_HOVER};
  }
`;

const ArrowButtonRight = styled.button`
  position: absolute;
  z-index: 100;
  right: 0px;
  top: 12px;
  background-color: rgba(32, 47, 64, 0.6);
  border: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  color: ${Color.SECONDARY};
  transition: background-color 0.3s ease;

  &:hover {
    background-color: ${Color.TAG_HOVER};
  }
`;

const TagWrapper = styled.div<{
  isActive: boolean;
  isFirst: boolean;
  isCovered?: boolean;
  last?: boolean;
  disabled?: boolean;
}>`
  display: flex;
  align-items: center;
  cursor: pointer;
  border: 1px solid
    ${({ isActive, disabled }) => ((disabled || isActive) ? "none" : "rgba(90, 171, 229, 0.5)")};
  color: ${Color.SECONDARY};
  padding: ${({ disabled }) => disabled ? '0px': `8px 12px`};
  border-radius: 4px;
  background-color: ${({ isActive }) =>
    isActive ? Color.BUTTON_BLUE : "transparent"};
  pointer-events: ${({ disabled }) => (disabled ? "none" : "auto")};
  &:hover {
    background-color: ${({ isActive }) =>
      isActive ? Color.BUTTON_BLUE : Color.TAG_HOVER};
  }
  margin-left: ${({ isFirst, disabled }) => ((disabled || isFirst) ? "0px" : "8px")};
  // min-width: ${({ isCovered }) => (isCovered ? "none" : "56px")};
  width: ${({ isCovered, disabled }) => (disabled ? '0px': isCovered ? "calc(20% - 32px)" : "auto")};
  justify-content: center;
  opacity: ${({ last }) => (last ? 0 : 1)};
`;

const Gradient = styled.div`
  position: absolute;
  width: 80px;
  height: 32px;
  right: 0px;
  bottom: 0px;
  pointer-events: none;
  background: linear-gradient(270deg, #161925 0%, rgba(22, 25, 37, 0) 100%);
`;

const Gradient2 = styled.div`
  position: absolute;
  width: 80px;
  height: 32px;
  left: 0px;
  bottom: 0px;
  pointer-events: none;
  z-index: 1;
  transform: rotate(180deg);
  background: linear-gradient(270deg, #161925 0%, rgba(22, 25, 37, 0) 100%);
`;

const TextStyled = styled(Text)<{ isCovered?: boolean }>`
  clear: both;
  display: inline-block;
  overflow: hidden;
  white-space: nowrap;
  min-width: ${({ isCovered }) => (isCovered ? "none" : "56px")};
`;

interface TagsListProps {
  tagsList: string[];
  activeTag: string;
  withoutShadow?: boolean;
  setActiveTag: (tag: string) => void;
  isCovered?: boolean;
  disabledTags?: number[];
}

export function TagsList({
  tagsList,
  activeTag,
  withoutShadow,
  isCovered,
  disabledTags,
  setActiveTag,
}: TagsListProps) {
  const scrollRef = useRef<Scrollbars>(null);
  tagsList = isCovered ? tagsList : [...tagsList, "I"];
  const [showLeftShadow, setShowLeftShadow] = useState(false);
  const [showRightArrow, setShowRightArrow] = useState(false);
  const { t } = useCustomTranslation();
  const { isMobile } = useQueryParams();

  const scrollToLeft = () => {
    //@ts-ignore
    const { scrollLeft } = scrollRef.current.getValues();
    const scrollAmount = 100;
    //@ts-ignore
    scrollRef.current.scrollLeft(scrollLeft - scrollAmount);
  };

  const scrollToRight = () => {
    //@ts-ignore
    const { scrollLeft } = scrollRef.current.getValues();
    const scrollAmount = 100;
    //@ts-ignore
    scrollRef.current?.scrollLeft(scrollLeft + scrollAmount);
  };

  return (
    <TagsListWrapper isCovered={isCovered}>
      {showLeftShadow && !withoutShadow && <Gradient2 />}
      {showLeftShadow && isMobile !== 'true' && (
        <ArrowButtonLeft onClick={scrollToLeft}>
          <Icon icon={IconType.chevronLeft} size={24} />
        </ArrowButtonLeft>
      )}
      <ScrolAreaHideScroll
        size={ScrollAreaSize.S}
        ref={scrollRef}
        hideVerticalTrack={true}
        hideHorizontalTrack={isMobile !== 'true'}
        onUpdate={(data) => {
          setShowLeftShadow(data.scrollLeft > 40);
          setShowRightArrow(
            data.scrollLeft < data.scrollWidth - data.clientWidth - 1
          );
        }}
      >
        <Wrapper>
          {tagsList.map((tag: string, index: number) => (
            <TagWrapper
              key={`tag_${tag}`}
              isActive={tag === activeTag}
              isFirst={tagsList.indexOf(tag) == 0}
              onClick={() => setActiveTag(tag)}
              isCovered={isCovered}
              last={!isCovered && tagsList.indexOf(tag) == tagsList.length - 1}
              disabled={
                tagsList.indexOf(tag) == tagsList.length - 1 ||
                (disabledTags ? !!disabledTags.find((i) => i === index) : false)
              }
            >
              <TextStyled
                size={TextSize.XS}
                weight={TextWeight.MEDIUM}
                color={Color.SECONDARY}
                breakWords={false}
                isCovered={isCovered || tagsList.indexOf(tag) == tagsList.length - 1}
              >
                {(tag === DefaultTagsEnum.ALL && t(DefaultTagsEnum.ALL)) ||
                  (tag === DefaultTagsEnum.REAL_MONEY &&
                    t(DefaultTagsEnum.REAL_MONEY)) ||
                  tag}
              </TextStyled>
            </TagWrapper>
          ))}
        </Wrapper>
      </ScrolAreaHideScroll>
      {showRightArrow && isMobile !== 'true' && (
        <ArrowButtonRight onClick={scrollToRight}>
          <Icon icon={IconType.chevronRight} size={24} />
        </ArrowButtonRight>
      )}
      {!withoutShadow && <Gradient />}
    </TagsListWrapper>
  );
}
