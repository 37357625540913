import {
  ReactNode, useState
} from "react";
import { useWalletConnectClient } from "../context/ClientContext";
import PairingModal from "../features/BuyBonuses/components/modals/PairingModal";
import styled from "styled-components";
import { Button, ButtonSize } from "../shared/components/Button";
import Modal from "../features/BuyBonuses/components/Modal";
import { useEffectSkipFirst } from "../shared/hooks/useEffectSkipFirst";
import { useAppSelector } from "../app/hooks";
import { truncateMiddle } from "../utils/utils";

export const SConnectButton = styled(Button as any)`
  position: absolute;
  right: 8px;
  top: 8px;
  z-index: 10000;
`;

const WalletConnectLayoutWrapper = styled.div`
  position: relative;

`;

export function WalletConnectLayout({
  children,
}: {
  children: ReactNode | ReactNode[];
}) {
  const { client, session, connect, disconnect, accounts } =
    useWalletConnectClient();
  const userInfo = useAppSelector((state) => state.userInfo.userInfo);
  const [connectedAddress, setConnectedAddress] = useState('');

  useEffectSkipFirst(() => {
    if (session?.topic) {
      const account = accounts[0].replace(/^eip155:137:/, '')

      setConnectedAddress(account);
    }
  }, [session?.topic])

  const onConnect = () => {
    if (typeof client === "undefined") {
      throw new Error("WalletConnect is not initialized");
    }
    else {
      connect();
    }
  };

  return (
    <WalletConnectLayoutWrapper>
      {/* {connectedAddress ? <SConnectButton size={ButtonSize.S} onClick={() => {console.log('address Clicked')
      disconnect().then(() => {
        localStorage.clear()
        setConnectedAddress('');
        window.location.reload();
      });
      
      // window.location.reload();
    }}>
        {truncateMiddle(connectedAddress, 22)}
      </SConnectButton> :
        <SConnectButton size={ButtonSize.S} left onClick={onConnect}>
          Connect Wallet
        </SConnectButton>} */}
      {children}
    </WalletConnectLayoutWrapper>
  );
}
