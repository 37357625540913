import { createSearchParams, useNavigate } from "react-router-dom";
import { pickBy, identity } from 'lodash'
import { useQueryParams } from "../../utils/utils";
import { LangEnum } from "../../features/locale/redux/locale.slice";

export const useNavigateWithSearchParams = () => {
    const { foreignId, operatorId, sid, isMobile, lang, helpLink, callback } = useQueryParams()
    const navigate = useNavigate()
    
    const navigateWithSearchParams = (values: any, pathname: string) => {
      values = pickBy(values, identity)
      console.log(values, 'VALUES')
      
      navigate({
        pathname,
        search: `?${createSearchParams({ ...values, foreignId, operatorId, sid, isMobile, lang: lang || LangEnum.en, helpLink, callback })}`,
      });
    }

  return { navigateWithSearchParams }
};