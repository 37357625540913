import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { motion } from "framer-motion";
import { useAppSelector } from "../../app/hooks";
import { Color } from "../../constants/ColorEnum";
import TextAlign from "../../constants/TextAlign";
import { TextSize } from "../../constants/TextSize";
import { TextWeight } from "../../constants/TextWeight";
import { WhiteSpace } from "../../constants/WhiteSpace";
import { IncreaseWithTooltip } from "../../features/cashback/components/IncreaseWithTooltip";
import { SUCCESS_MESSAGE_PROCESSING } from "../../features/cashback/redux/userInfo.slice";
import { formatPriceNumberWithCurrency } from "../../utils/utils";
import { Text } from "./Text";

export type DoubleCurrencyProps = {
  value: number | string;
  valueUsd?: number | string;
  texSizeFwd?: TextSize;
  textSizeUsd?: TextSize;
  withFwdSign?: boolean;
  margin?: number;
  marginRight?: string;
  marginLeft?: string;
  textColorUsd?: Color;
  tooltipCollapsed: boolean;
  // withIncreaseTooltip?: boolean;
  increaseTooltipMargin?: string;
  textHeightFWD?: string;
  TextWeightFWD?: TextWeight;
  textHeightUSD?: string;
  hideUsd?: boolean;
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const TextStyled = styled(Text)<{
  margin?: number;
  marginRight?: string;
  marginLeft?: string;
  textHeightUSD?: string;
}>`
  margin-top: ${({ margin }) => (margin !== undefined ? `${margin}px` : "4px")};
  text-align: right;

  margin-right: ${({ marginRight }) =>
    marginRight !== undefined ? `${marginRight}` : "0"};
  margin-left: ${({ marginLeft }) =>
    marginLeft !== undefined ? `${marginLeft}` : "0"};

  line-height: ${({ textHeightUSD }) =>
    textHeightUSD ? `${textHeightUSD}` : "auto"};
`;

const Wrap = styled.div`
  display: flex;
`;

const TextFWD = styled(Text)<{ textHeightFWD?: string }>`
  line-height: ${({ textHeightFWD }) =>
    textHeightFWD ? `${textHeightFWD}` : "auto"};
`;

const BadgeWrap = styled.div`
  margin: 8px 4px 0 0;
`;

export function DoubleCurrencyWithAnimatedIncrease({
  value,
  valueUsd,
  texSizeFwd,
  textSizeUsd,
  withFwdSign,
  margin,
  marginRight,
  marginLeft,
  textColorUsd,
  // withIncreaseTooltip,
  tooltipCollapsed,
  increaseTooltipMargin,
  textHeightFWD,
  TextWeightFWD,
  textHeightUSD,
  hideUsd,
}: DoubleCurrencyProps) {
  const poolRate = useAppSelector(
    (state) => state.userInfo.commonInfo.info?.poolRate
  );

  const balanceIsPending = useAppSelector(
    (state) => state.userInfo.balanceIsPending
  );

  const [balanceIsPendingWith5000, setBalanceIsPendingWith5000] =
    useState(false);

  useEffect(() => {
    if (balanceIsPending) {
      setBalanceIsPendingWith5000(balanceIsPending);
    } else {
      const timeout = setTimeout(() => {
        // Update the value after the timeout
        setBalanceIsPendingWith5000(!!balanceIsPending);
      }, SUCCESS_MESSAGE_PROCESSING);

      return () => clearTimeout(timeout);
    }
  }, [balanceIsPending]);
  const showSubmiTxMessage = useAppSelector(state => state.userInfo.showSubmiTxMessage);

  return (
    <Wrapper>
      <TextFWD
        size={texSizeFwd || TextSize.XXL}
        weight={TextWeightFWD || TextWeight.BOLD}
        color={Color.SECONDARY}
        align={TextAlign.RIGHT}
        textHeightFWD={textHeightFWD}
      >
        {formatPriceNumberWithCurrency({
          number: `${value}`,
        })}
        {withFwdSign && " FWD"}
      </TextFWD>
      {!hideUsd && (
        <Wrap>
          {(!balanceIsPendingWith5000 && !showSubmiTxMessage) && (
            <motion.div
              initial={true}
              animate={tooltipCollapsed ? "collapsed" : "expanded"}
              variants={{
                collapsed: { opacity: 0, height: 0, margin: 0 },
                expanded: { opacity: 1 },
              }}
              transition={{
                duration: 0.3,
              }}
            >
              <BadgeWrap>
                <IncreaseWithTooltip />
              </BadgeWrap>
            </motion.div>
          )}
          {/* {withIncreaseTooltip && (
            <MarginTop increaseTooltipMargin={increaseTooltipMargin || "7px"}>
              <IncreaseWithTooltip />
            </MarginTop>
          )} */}

          <TextStyled
            size={textSizeUsd || TextSize.XS}
            color={textColorUsd || Color.SECONDARY50}
            margin={margin}
            marginRight={marginRight}
            marginLeft={marginLeft}
            textHeightUSD={textHeightUSD}
            whiteSpace={WhiteSpace.NOWRAP}
          >
            {formatPriceNumberWithCurrency({
              number: `${valueUsd ? valueUsd : poolRate && +value * +poolRate}`,
            })}{" "}
            USDT
          </TextStyled>
        </Wrap>
      )}
    </Wrapper>
  );
}
