import { createSlice } from "@reduxjs/toolkit";
import { Loading } from "../../../constants/Loading";
import { IPurchase } from "../components/PurchaseFirstLevel";
import { IEventForDate } from "../pages/cashback/CashbackSecondDetailed";
import { ITokenBurnItem } from "../pages/TokenBurn/TokenBurnItem";
import { getEventForDay, getEventJournal, getPurchases, getTokenBurn, IEventFirstLevel } from "./actions";
import { formatEventsFirstLevel } from "./utils/formatEventsFirstLevel";
import { getExpirationDate } from "./utils/getExpirationDate";
import { getOldestRecordWithStatusTwo } from "./utils/getOldestRecordWithStatusTwo";

export type IDayInfo = {
  1?: number;
  2?: number;
  3?: number;
  20?: number;
  date: string;
};

export type HistoryState = {
  eventsFirstLevel: {
    loading: Loading;
    result?: IDayInfo[];
    list?: IEventFirstLevel[];
    errorMessage?: string;
  };
  eventForDay: {
    loading: Loading;
    result?: IEventForDate[];
    errorMessage?: string;
  },
  lastRecordWithStatusTwo: {
    timeLeft?: number;
    amount?: number;
  },
  purchasesFirstLevel: {
    loading: Loading;
    result?: IPurchase[];
    errorMessage?: string;
  };

  tokenBurn: {
    loading: Loading;
    result?: ITokenBurnItem[];
    errorMessage?: string;
  };
};

export const initialState: HistoryState = {
  eventsFirstLevel: {
    loading: Loading.INITIAL,
  },
  eventForDay: {
    loading: Loading.INITIAL,
  },
  lastRecordWithStatusTwo: {},
  purchasesFirstLevel: {
    loading: Loading.INITIAL,
    result: []
  },
  tokenBurn: {
    loading: Loading.INITIAL,
    result: []
  },
};

export const eventStatus: { [key: number]: { title: string } } = {
  1: { title: "Claimed" },
  2: { title: "Not Claimed" },
  3: { title: "Expired" },
  4: { title: "Sent To JackPot" },
  5: { title: "In Progress" },
  6: { title: "Failed" },
  7: { title: "Mined" },
  8: { title: "Claim Transaction" },
};

export const historySlice = createSlice({
  name: "history",
  initialState,
  reducers: {
    resetLastRecordWithStatusTwo: (state) => {
      state.lastRecordWithStatusTwo.amount = undefined;
      state.lastRecordWithStatusTwo.timeLeft = undefined;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(getEventJournal.pending, (state) => {
      state.eventsFirstLevel.loading = Loading.PENDING;
      state.eventsFirstLevel.errorMessage = undefined;
    });
    builder.addCase(getEventJournal.fulfilled, (state, action) => {
      state.eventsFirstLevel.loading = Loading.SUCCESS;
      const eventList = action.payload.result.sort((a: IEventFirstLevel, b: IEventFirstLevel) => new Date(b.eventDate).getTime() - new Date(a.eventDate).getTime())
      state.eventsFirstLevel.list = eventList;
      const firstLevelItems = formatEventsFirstLevel(
        eventList
      );

      state.eventsFirstLevel.result= firstLevelItems.reverse();

      const lastRecordWithStatusTwo = getOldestRecordWithStatusTwo(firstLevelItems);

      if (lastRecordWithStatusTwo && lastRecordWithStatusTwo[2]) {
        state.lastRecordWithStatusTwo.amount = lastRecordWithStatusTwo[2];

        state.lastRecordWithStatusTwo.timeLeft = getExpirationDate(lastRecordWithStatusTwo.date);
      }
    });
    builder.addCase(getEventJournal.rejected, (state, action) => {
      state.eventsFirstLevel.errorMessage = action.error.message;
      state.eventsFirstLevel.loading = Loading.ERROR;
    });

    builder.addCase(getEventForDay.pending, (state) => {
      state.eventForDay.loading = Loading.PENDING;
      state.eventForDay.errorMessage = undefined;
    });
    builder.addCase(getEventForDay.fulfilled, (state, action) => {
      state.eventForDay.loading = Loading.SUCCESS;
      state.eventForDay.result = action.payload.reverse();
    });
    builder.addCase(getEventForDay.rejected, (state, action) => {
      state.eventForDay.errorMessage = action.error.message;
      state.eventForDay.loading = Loading.ERROR;
    });
    builder.addCase(getPurchases.pending, (state) => {
      state.purchasesFirstLevel.loading = Loading.PENDING;
      state.purchasesFirstLevel.errorMessage = undefined;
    });
    builder.addCase(getPurchases.fulfilled, (state, action) => {
      state.purchasesFirstLevel.loading = Loading.SUCCESS;

      const firstLevelItems = action.payload.result

      state.purchasesFirstLevel.result= firstLevelItems;
    });
    builder.addCase(getPurchases.rejected, (state, action) => {
      state.purchasesFirstLevel.errorMessage = action.error.message;
      state.purchasesFirstLevel.loading = Loading.ERROR;
    });

    builder.addCase(getTokenBurn.pending, (state) => {
      state.tokenBurn.loading = Loading.PENDING;
      state.tokenBurn.errorMessage = undefined;
    });
    builder.addCase(getTokenBurn.fulfilled, (state, action) => {
      state.tokenBurn.loading = Loading.SUCCESS;
      const firstLevelItems = action.payload.result

      state.tokenBurn.result= firstLevelItems;
    });
    builder.addCase(getTokenBurn.rejected, (state, action) => {
      state.tokenBurn.errorMessage = action.error.message;
      state.tokenBurn.loading = Loading.ERROR;
    });

  },
});

export const { resetLastRecordWithStatusTwo} = historySlice.actions;

export default historySlice.reducer;
