import { FC } from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";
import { RoutesTypes } from "../constants/RoutesTypes";
import { AuthForm } from "../features/auth/components/AuthForm";
import { BuyBonuses } from "../features/BuyBonuses/components/BuyBonuses";
import { Cashback } from "../features/cashback/components/Cashback";
import { Exchange } from "../features/Exchange/components/Exchange";
import { QuickExchange } from "../features/Exchange/components/QuickExchange";
import { BonusPurchase } from "../features/History/pages/BonusPurchase/BonusPurchase";
import { BonusPurchaseDetaailed } from "../features/History/pages/BonusPurchase/BonusPurchaseDetailed";
import { HistoryCashback } from "../features/History/pages/cashback/Cashback";
import { CashbackFirstLevel } from "../features/History/pages/cashback/CashbackFirstLevel";
import { CashbackSecondDetailed } from "../features/History/pages/cashback/CashbackSecondDetailed";
import { CashbackSecondLevel } from "../features/History/pages/cashback/CashbackSecondLevel";
import { CashbackSecondSimplified } from "../features/History/pages/cashback/CashbackSecondSimplified";
import { CashbackThirdLevel } from "../features/History/pages/cashback/CashbackThirdLevel";
import { History } from "../features/History/pages/History";
import { TokenBurn } from "../features/History/pages/TokenBurn/TokenBurn";
import { HowItWorks } from "../features/HowItWorks/components/HowItWorks";
import { InstallMetamask } from "../features/InstallMetamask/pages/InstallMetamask";
import { MobileCoinbase } from "../features/MobileMetamask/page/MobileCoinbase";
import { MobileMetamask } from "../features/MobileMetamask/page/MobileMetamask";
import { SwithcNetwork } from "../features/SwitchNetwork/pages/SwitchNetwork";
import { SwitchWallet } from "../features/SwitchWallet/pages/SwitchWallet";
import { WalletSettings } from "../features/WalletSettings/pages/WalletSettings";
import { MainLayout } from "../layouts/Main";
import { AuthError } from "../shared/components/AuthError";

const AppRoutes: FC = () => {
  return (
    <Router>
      <Routes>
        <Route element={<MainLayout />}>
          <Route path={RoutesTypes.dashboard} element={<Cashback />} />
          <Route path={RoutesTypes.buy_bonuses} element={<BuyBonuses />} />
          <Route path={RoutesTypes.history} element={<History />}>
            <Route
              path={RoutesTypes.historyCashback}
              element={<HistoryCashback />}
            >
              <Route
                path={RoutesTypes.historyCashbackFirst}
                element={<CashbackFirstLevel />}
              />
              <Route
                path={RoutesTypes.historyCashbackSecond}
                element={<CashbackSecondLevel />}
              >
                <Route
                  path={RoutesTypes.historyCashbackSecondSimplified}
                  element={<CashbackSecondSimplified />}
                />
                <Route
                  path={`${RoutesTypes.historyCashbackSecondDetailed}/:date`}
                  element={<CashbackSecondDetailed />}
                />
              </Route>
              <Route
                path={`${RoutesTypes.historyCashbackThird}/:id`}
                element={<CashbackThirdLevel />}
              />
            </Route>
            <Route
              path={RoutesTypes.historyTokenBurn}
              element={<TokenBurn />}
            />
            <Route
              path={RoutesTypes.historyBonusPurchase}
              element={<BonusPurchase />}
            />
            <Route
              path={`${RoutesTypes.historyBonusPurchaseSecond}/:txhash`}
              element={<BonusPurchaseDetaailed />}
            />
          </Route>
          <Route path={RoutesTypes.exchange} element={<Exchange />} />
          <Route path={RoutesTypes.quickExchange} element={<QuickExchange />} />
          <Route
            path={RoutesTypes.walletSettings}
            element={<WalletSettings />}
          />
          <Route path={RoutesTypes.howItWorks} element={<HowItWorks />} />
          <Route path={RoutesTypes.SwitchNetwork} element={<SwithcNetwork />} />
          <Route path={RoutesTypes.switchWallet} element={<SwitchWallet />} />
          <Route
            path={RoutesTypes.installMetamask}
            element={<InstallMetamask />}
          />
          <Route
            path={RoutesTypes.mobileMetamask}
            element={<MobileMetamask />}
          />
          <Route
            path={RoutesTypes.mobileCoinbase}
            element={<MobileCoinbase />}
          />
        </Route>
        <Route path={RoutesTypes.auth} element={<AuthForm />} />
        <Route path="*" element={<Navigate to={RoutesTypes.auth} />} />
        <Route path={RoutesTypes.authError} element={<AuthError />} />
      </Routes>
    </Router>
  );
};

export { AppRoutes };
