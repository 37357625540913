import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { Color } from "../../../constants/ColorEnum";
import { Text } from "../../../shared/components/Text";
import {
  Button,
  ButtonSize,
  ButtonType,
} from "../../../shared/components/Button";
import { TextSize } from "../../../constants/TextSize";
import { TextWeight } from "../../../constants/TextWeight";
import {
  buyBonusWithoutSign,
  getContractDetails,
  getPermitSignature,
  TxStatus,
} from "../redux/actions";
import { ProcessStatus } from "./BuyBonuses";
import { AppLoading } from "../../../shared/components/AppLoader";
import { AppError } from "../../../shared/components/AppError";
import { ActionRequered } from "../../../shared/components/ActionRequired";
import { AppSuccess } from "../../../shared/components/AppSuccess";
import { HashCopy } from "../../../shared/components/HashCopy";
import { useEffectSkipFirst } from "../../../shared/hooks/useEffectSkipFirst";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import {
  RedirectParentToIframeUrlIfSafari,
  useQueryParams,
} from "../../../utils/utils";
import { RoutesTypes } from "../../../constants/RoutesTypes";
import { useNavigateWithSearchParams } from "../../../shared/hooks/useNavigateWithSearchParams";
import InstallMetamask from "../../../resources/images/installMetamask.png";
import { IncorrectAddressAction } from "../../SwitchWallet/components/IncorrectAddressActtion";
import {
  setBalanceIsPending,
  setShowSubmiTxMessage,
  TxHashEnum,
  WalletTypeEnum,
} from "../../cashback/redux/userInfo.slice";
import { BonusSchema, DefaultTagsEnum, TagsSchema } from "../redux/bonus.slice";
import { useCustomTranslation } from "../../locale/hooks/useCustomTranslation";
import { DECIMALS_CONST } from "./Exchange";
import { ethers } from "ethers";
import { DEFAULT_EIP155_METHODS, DEFAULT_EIP155_OPTIONAL_METHODS } from "../../../constants";
import { useWalletConnectClient } from "../../../context/ClientContext";
import { getNonceUrlProvider } from "../../../helpers/getNonceUrlProvider";
import { spentWithSponsorship } from "../utils/spentWithSponsorship";
import { getTxStatusBySid, getUserInfo } from "../../cashback/redux/actions";
import { ActionRequeredWC2 } from "../../../shared/components/ActionRequiredWC2";

import ReactQuill from "react-quill";
import "react-quill/dist/quill.bubble.css";
import { DoubleCurrency } from "../../../shared/components/DoubleCurrency";
import { useWeb3React } from "@web3-react/core";
import { connectors } from "../../cashback/components/connectors";
import { ActionRequeredCB } from "../../../shared/components/ActionRequiredCB";
import { useDetectCoinbase } from "../../../shared/hooks/useDetectCoinbase";
import { whiteListForHistory } from "../../cashback/components/ClaimTokens";
import { CancelTx } from "./CancelTx";

const Wrapper = styled.div`
  display: flex;
  box-sizing: border-box;
  position: relative;
  justify-content: space-between;
  align-items: flex-start;
  padding-bottom: 16px;
`;

interface BonusListItemProps {
  bonusItem: BonusSchema;
  setOpenItem: (bonus: BonusSchema | null) => void;
  buyNow: boolean;
  emitProcessStatus: (status: ProcessStatus) => void;
}

const BonusListImage = styled.img`
  width: 64px;
  height: 64px;
  object-fit: cover;

  background-color: ${Color.NAVIGATION_WRAPPER};
  border-radius: 8px;
  
  img:not([src]) {
    border-color: transparent;
  }
`;

const InfoColumn = styled.div`
  display: flex;
  flex-direction: column;

  align-items: flex-start;
  height: 100%;
  padding: 0 16px;
  text-align: left;
  width: calc(100% - 96px);
`;

const Separator = styled.div`
  position: absolute;
  bottom: 8px;
  width: calc(100% - 16px);
  height: 1px;
  background-color: rgba(90, 171, 229, 0.1);
`;

const BonusItemWrapper = styled.div`
  padding: 16px;
  height: auto;
  border-radius: 8px;
  box-sizing: border-box;
  width: calc(100% - 0px);
  background-color: ${Color.NAVIGATION_WRAPPER};
`;

const DesctiptionBlock = styled(Text)`
  margin: 16px 0;
`;

const ButtonStyled = styled(Button)`
  margin-top: 16px;
`;

const TextSecond = styled(Text)`
  line-height: 28px;
`;

const WhiteTextEditor = styled.div`
  position: relative; 
  margin-top: 16px;

  .ql-editor {
    color: ${Color.SECONDARY80};
    opacity: 80%;
    padding: 0;

    a {
      color: ${Color.COLOR_LINK_QUILL};
    }
  }

  &:empty {
    background-color: white; /* Change the style for empty elements */
  }

  margin-bottom: 16px;

  ::after {
    content: "";
    position: absolute;
    bottom: -16px;
    left: 0;
    width: 100%;
    height: 1px;
    background-color: rgba(90, 171, 229, 0.1);
  }
`;

const TextWithMargin = styled(Text)`
  margin-bottom: 4px;
`;

const PriceSummary = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between; 
  margin: 24px 0 16px;
`;

export function BonusListItem({
  bonusItem,
  setOpenItem,
  buyNow,
  emitProcessStatus,
}: BonusListItemProps) {
  const [processStatus, setProcessStatus] = useState<ProcessStatus>(
    ProcessStatus.CARDS_LIST
  );
  const { userInfo } = useAppSelector((state) => state.userInfo);
  const [hashCreated, setHashCreated] = useState();
  const { navigateWithSearchParams } = useNavigateWithSearchParams();
  const dispatch = useAppDispatch();
  const [errorMessage, setErrorMessage] = useState('');

  const { sid, isMobile } = useQueryParams();
  const {
    client,
    session,
    connect,
    accounts,
  } = useWalletConnectClient();

  
  const [toggleSign3, setToggleSign3] = useState(false);

  useEffect(() => {
    if (toggleSign3) {
      console.log('TOGGLE')
      signPermissionCoinbase(bonusItem);
    }
  }, [toggleSign3]);

  const { isCoinbase } = useDetectCoinbase();

  const proceedPurchase = () => {
    if (userInfo?.address) {
      if (userInfo?.addressMemo === WalletTypeEnum.COINBASE) {

        if (isMobile === "true" && !isCoinbase) {
          navigateWithSearchParams({}, RoutesTypes.mobileCoinbase);
          return;
        }


        if (!account) {
          activate(connectors.coinbaseWallet).then(() => {
            // setTimeout(() => sign3(), 500)
            setToggleSign3(true);
          });
        } else {
          console.log('33333')
          signPermissionCoinbase(bonusItem);
        }
      } else {
        signPermission(bonusItem);
      }
    } else {
      setProcessStatus(ProcessStatus.LOADING)
      buyBonusWithoutSign({sid, resourceId: bonusItem.id}).then((res) => {
        setProcessStatus(ProcessStatus.SUCCESS);
        setTimeout(() => {
          dispatch(getUserInfo({ sid }));
        }, 1000)
      }).catch((err) => {
        setProcessStatus(ProcessStatus.ERROR)
        setErrorMessage(err.response.data.message);
      });
    }
  }



  // const coinbasePermission = async () => {
  //   const {
  //     Operator: { address: operatorContractAddress },
  //     Core: { address: tokenContractAddress, abi },
  //     ProtocolName: protocolName,
  //   } = await getContractDetails();

  //   getPermitSignature(
  //     bonusItem,
  //     setProcessStatus,
  //     setHashCreated,
  //     tokenContractAddress,
  //     operatorContractAddress,
  //     bonusItem.usdPrice,
  //     protocolName
  //   )
  // }

  const signPermission = async (card: BonusSchema) => {
    RedirectParentToIframeUrlIfSafari();
    if (!accounts[0]) {
      connect();
      return;
    }

    if (accounts[0]?.toLowerCase() !== userInfo?.address?.toLowerCase()) {
      navigateWithSearchParams({}, RoutesTypes.switchWallet);
    }
    setProcessStatus(ProcessStatus.LOADING);
    try {
      const plusDay = Math.floor(Date.now() / 1000) + 86400;
      const deadline = ethers.utils.hexlify(plusDay);
      const ethersAmount = ethers.utils
        .parseEther(
          String(
            userInfo?.poolRate ? card.usdPrice / +userInfo?.poolRate : 0 * 1.2
          )
        )
        .toString();

      const {
        Operator: { address: operatorContractAddress },
        Core: { address: tokenContractAddress, abi },
        ProtocolName: protocolName,
      } = await getContractDetails({ sid });

      const nonce = await getNonceUrlProvider(
        userInfo?.address || "",
        tokenContractAddress,
        abi
      );

      const message2 = {
        owner: userInfo?.address,
        spender: operatorContractAddress,
        value: ethersAmount,
        nonce,
        deadline,
      };

      const typedData = {
        types: {
          EIP712Domain: [
            {
              name: "name",
              type: "string",
            },
            {
              name: "version",
              type: "string",
            },
            {
              name: "chainId",
              type: "uint256",
            },
            {
              name: "verifyingContract",
              type: "address",
            },
          ],
          Permit: [
            {
              name: "owner",
              type: "address",
            },
            {
              name: "spender",
              type: "address",
            },
            {
              name: "value",
              type: "uint256",
            },
            {
              name: "nonce",
              type: "uint256",
            },
            {
              name: "deadline",
              type: "uint256",
            },
          ],
        },
        primaryType: "Permit",
        domain: {
          name: protocolName,
          version: "1",
          chainId: 137,
          verifyingContract: tokenContractAddress,
        },
        message: message2,
      };

      const message = JSON.stringify(typedData);
      const params = [userInfo?.address, message];
      let signature = "";
      setProcessStatus(ProcessStatus.WAITING_SIGN);
      try {
        signature = await client!.request<string>({
          topic: session!.topic,
          chainId: "eip155:137",
          request: {
            method: DEFAULT_EIP155_OPTIONAL_METHODS.ETH_SIGN_TYPED_DATA_V4,
            params,
          },
        });
      } catch (err) {
        setProcessStatus(ProcessStatus.CANCEL);
      }
      const { r, s, v } = ethers.utils.splitSignature(signature);
      setProcessStatus(ProcessStatus.LOADING);
      let res;

      try {
        dispatch(setShowSubmiTxMessage(true));
        res = await spentWithSponsorship({
          r,
          v,
          s,
          user: userInfo?.address,
          resource: card.foreignIdHash || "",
          amount: ethersAmount,
          deadline: plusDay,
        });
      } catch (err) {
      } finally {
        setTimeout(() => {
          dispatch(getTxStatusBySid({ sid })).then((result) => {
            if (
              !(
                result.type === "userInfo/getClaimStatusBySid/fulfilled" &&
                Array.isArray(result.payload) &&
                result.payload.length > 0
              )
            ) {
              setProcessStatus(ProcessStatus.ERROR);
            }
          });
        }, 1000);
      }
      if (res.hash) {
        setProcessStatus(ProcessStatus.WAITING_CONFIRMATION);
        setHashCreated(res.hash);
      }
    } catch (error) {
      setProcessStatus(ProcessStatus.CANCEL);
      console.error("An error occurred:", error);
    }
  };

  const { library, account, activate } = useWeb3React();

  const signPermissionCoinbase = async (card: BonusSchema) => {
    if (isMobile === "true") {
      navigateWithSearchParams({}, RoutesTypes.mobileCoinbase);
      return;
    }

    if (!account) {
      await activate(connectors.coinbaseWallet);
      return;
    }

    if (account?.toLowerCase() !== userInfo?.address?.toLowerCase()) {
      navigateWithSearchParams({}, RoutesTypes.switchWallet);
    }

    setProcessStatus(ProcessStatus.LOADING);
    try {
      const plusDay = Math.floor(Date.now() / 1000) + 86400;
      const deadline = ethers.utils.hexlify(plusDay);
      const ethersAmount = ethers.utils
        .parseEther(
          String(
            userInfo?.poolRate ? card.usdPrice / +userInfo?.poolRate : 0 * 1.2
          )
        )
        .toString();

      const {
        Operator: { address: operatorContractAddress },
        Core: { address: tokenContractAddress, abi },
        ProtocolName: protocolName,
      } = await getContractDetails({ sid });

      const nonce = await getNonceUrlProvider(
        account || "",
        tokenContractAddress,
        abi
      );

      console.log(nonce, "NONCe");
      const message2 = {
        owner: account,
        spender: operatorContractAddress,
        value: ethersAmount,
        nonce,
        deadline,
      };

      const typedData = {
        types: {
          EIP712Domain: [
            {
              name: "name",
              type: "string",
            },
            {
              name: "version",
              type: "string",
            },
            {
              name: "chainId",
              type: "uint256",
            },
            {
              name: "verifyingContract",
              type: "address",
            },
          ],
          Permit: [
            {
              name: "owner",
              type: "address",
            },
            {
              name: "spender",
              type: "address",
            },
            {
              name: "value",
              type: "uint256",
            },
            {
              name: "nonce",
              type: "uint256",
            },
            {
              name: "deadline",
              type: "uint256",
            },
          ],
        },
        primaryType: "Permit",
        domain: {
          name: protocolName,
          version: "1",
          chainId: 137,
          verifyingContract: tokenContractAddress,
        },
        message: message2,
      };

      const message = JSON.stringify(typedData);
      const params = [userInfo?.address, message];
      let signature = "";
      setProcessStatus(ProcessStatus.WAITING_SIGN);
      // try {
      //   signature = await client!.request<string>({
      //     topic: session!.topic,
      //     chainId: "eip155:137",
      //     request: {
      //       method: DEFAULT_EIP155_OPTIONAL_METHODS.ETH_SIGN_TYPED_DATA_V4,
      //       params,
      //     },
      //   });
      // } catch (err) {
      //   console.log(err);
      //   setProcessStatus(ProcessStatus.CANCEL);
      // }

      // const { r, s, v } = ethers.utils.splitSignature(signature);
      // console.log(r, s, v, "r, s, v");
      // setProcessStatus(ProcessStatus.LOADING);

      await library.provider
        .request({
          method: DEFAULT_EIP155_METHODS.ETH_SIGN_TYPED_DATA_V4,
          params: [account, typedData],
        })
        .then(async (signature: string) => {
          const { r, s, v } = ethers.utils.splitSignature(signature);
          console.log(r, s, v, "r, s, v");
          setProcessStatus(ProcessStatus.LOADING);
          let res;
          try {
            res = await spentWithSponsorship({
              r,
              v,
              s,
              user: userInfo?.address,
              resource: card.foreignIdHash || "",
              amount: ethersAmount,
              deadline: plusDay,
            });
          } catch (err) {
            setProcessStatus(ProcessStatus.ERROR);
          } finally {
            setTimeout(() => {
              dispatch(getTxStatusBySid({ sid })).then((result) => {
                if (
                  !(
                    result.type === "userInfo/getClaimStatusBySid/fulfilled" &&
                    Array.isArray(result.payload) &&
                    result.payload.length > 0
                  )
                ) {
                  setProcessStatus(ProcessStatus.ERROR);
                }
              });
            }, 1000);
          }

          if (res) {
            setProcessStatus(ProcessStatus.WAITING_CONFIRMATION);
            setHashCreated(res.hash);
          }
        });
      
    } catch (error) {
      setProcessStatus(ProcessStatus.CANCEL);
      console.error("An error occurred:", error);
    }
  };

  const showSubmiTxMessage = useAppSelector(state => state.userInfo.showSubmiTxMessage);

  const activeTxHash = useAppSelector((state) => state.userInfo.activeTxHash);
  const activeTxHashStatus = useAppSelector(
    (state) => state.userInfo.activeTxHashStatus
  );
  const activeTxHashUsage = useAppSelector(
    (state) => state.userInfo.activeTxHashUsage
  );

  const options = {
    replace: (domNode: any) => {
      if (domNode.attribs && domNode.attribs.class === "remove") {
        return <></>;
      }
    },
  };

  useEffect(() => {
    if (processStatus !== ProcessStatus.CARDS_LIST) {
      if (
        activeTxHash &&
        activeTxHashStatus === TxStatus.MINED &&
        activeTxHashUsage === TxHashEnum.BUY_BONUS
      ) {
        setProcessStatus(ProcessStatus.SUCCESS);
      }

      if (
        activeTxHashStatus === TxStatus.FAILED &&
        activeTxHashUsage === TxHashEnum.BUY_BONUS
      ) {
        setProcessStatus(ProcessStatus.ERROR);
      }
    }

    if (
      processStatus === ProcessStatus.SUCCESS ||
      processStatus === ProcessStatus.ERROR
    ) {
      if (!activeTxHash && !activeTxHashStatus && !activeTxHashUsage) {
        setProcessStatus(ProcessStatus.CARDS_LIST);
      }
    }
  }, [activeTxHash, activeTxHashUsage, activeTxHashStatus]);

  useEffect(() => {
    if (processStatus === ProcessStatus.WAITING_CONFIRMATION && hashCreated) {
      dispatch(
        setBalanceIsPending({
          status: true,
          txHash: hashCreated,
          txHashUsage: TxHashEnum.BUY_BONUS,
          txString: t('yourPurchase'),
        })
      );
    }

    emitProcessStatus(processStatus);
  }, [processStatus]);

  useEffectSkipFirst(() => {
    if (buyNow) {
      proceedPurchase();
    }
  }, []);

  const balanceIsPending = useAppSelector(
    (state) => state.userInfo.balanceIsPending
  );

  const { t } = useCustomTranslation();

  const [showButton, setShowButton] = useState(true);

  useEffectSkipFirst(() => {
    client &&
      client.on("session_event", (event) => {
        if (
          event.params.event.name === "chainChanged" &&
          event.params.event.data === 137
        ) {
          setTimeout(() => {
            setShowButton(true);
            signPermission(bonusItem);
          }, 300);
        }
      });
  }, []);

  const commonInfo = useAppSelector((state) => state.userInfo.commonInfo);

  return (
    <>
      {(processStatus === ProcessStatus.LOADING && (
        <AppLoading message={t("loading")} fullHeight={true}></AppLoading>
      )) ||
        (processStatus === ProcessStatus.WAITING_ACCOUNT_SWITCH && (
          <IncorrectAddressAction />
        )) ||
        (processStatus === ProcessStatus.WAITING_NETWORK_SWITCH && (
          <ActionRequered
            actionMessage={t("actionRequired")}
            extraMessage={t("needSwithMetamask", { networkName: "Rewind" })}
          >
            <ButtonStyled
              buttonType={ButtonType.WHITE_BORDER}
              size={ButtonSize.XL}
              onClick={() => {
                setProcessStatus(ProcessStatus.CARDS_LIST);
                setOpenItem(null);
              }}
              fullWidth={true}
            >
              <Text
                size={TextSize.L}
                color={Color.SECONDARY}
                weight={TextWeight.BOLD}
              >
                {t("backToWidget")}
              </Text>
            </ButtonStyled>
          </ActionRequered>
        )) ||
        (processStatus === ProcessStatus.WAITING_SIGN && 
          (userInfo?.addressMemo === WalletTypeEnum.COINBASE ? (
          <ActionRequeredCB
            fullWidth={true}
            height={"calc(100vh - 100px)"}
          ></ActionRequeredCB>
        ) : (
          <ActionRequeredWC2
            fullWidth={true}
            height={"calc(100vh - 100px)"}
            // withWalletConnect={showButton}
          ></ActionRequeredWC2>
        ))) ||
        (processStatus === ProcessStatus.CARDS_LIST && (
          <BonusItemWrapper>
            <Wrapper>
              <BonusListImage src={bonusItem.iconUrl} />
              <InfoColumn>
                <TextWithMargin
                  color={Color.COLOR_BLUE_TAG}
                  size={TextSize.XXS}
                >
                  {bonusItem.tags
                    ?.filter(
                      (tag: TagsSchema) => tag.name !== DefaultTagsEnum.ALL
                    )
                    .map((tag: TagsSchema) => tag.name)
                    .join(", ")}
                </TextWithMargin>
                <Text
                  size={TextSize.M}
                  weight={TextWeight.MEDIUM}
                  color={Color.SECONDARY}
                >
                  {bonusItem.title}
                </Text>
              </InfoColumn>
              <Separator />
            </Wrapper>
            <WhiteTextEditor>
              <ReactQuill
                value={bonusItem.description}
                readOnly
                modules={{
                  toolbar: false,
                  clipboard: {
                    matchVisual: false,
                  },
                }}
                theme="bubble"
              />
            </WhiteTextEditor>
            <PriceSummary>
              <Text color={Color.COLOR_BLUE_TAG} size={TextSize.XS}>{t("totalPrice")}</Text>
              <DoubleCurrency
                value={String(
                  commonInfo.info?.poolRate
                    ? bonusItem.usdPrice / +commonInfo.info?.poolRate
                    : "0"
                )}
                valueUsd={String(bonusItem.usdPrice)}
                texSizeFwd={TextSize.XS}
                textSizeUsd={TextSize.XXS}
                withFwdSign={true}
                margin={2}
                textColorUsd={Color.COLOR_BLUE_TAG}
                marginLeft={'auto'}
              ></DoubleCurrency>
            </PriceSummary>
            <Button
              size={ButtonSize.XL}
              onClick={() => {
                proceedPurchase();
              }}
              fullWidth={true}
              disabled={
                (userInfo?.address? (!!userInfo?.usdtOnChainBalance &&
                  +userInfo.usdtOnChainBalance / DECIMALS_CONST <
                    +bonusItem.usdPrice) : (!!userInfo?.offChainBalance && 
                      //@ts-ignore
                      (+userInfo.offChainBalance/ DECIMALS_CONST * userInfo?.poolRate) <
                        +bonusItem.usdPrice) ) ||
                !bonusItem.isActive ||
                balanceIsPending || showSubmiTxMessage
              }
              // disabled={
              //   (userInfo?.address ? (!!userInfo?.usdtOnChainBalance &&
              //     +userInfo.usdtOnChainBalance / DECIMALS_CONST <
              //       +bonusItem.usdPrice) : 
              //       ((!!userInfo?.offChainBalance && 
              //         //@ts-ignore
              //         ((+userInfo.offChainBalance/ DECIMALS_CONST * userInfo?.poolRate) <
              //           +bonusItem.usdPrice) || 
              //             !whiteListForHistory.find((el) => el === userInfo?.userId)
              //           ))
              //       ) ||
              //   !bonusItem.isActive ||
              //   balanceIsPending
              // }
            >
              <Text
                size={TextSize.L}
                color={Color.SECONDARY}
                weight={TextWeight.BOLD}
              >

                {t("confirmPurchase")}
              </Text>
            </Button>
          </BonusItemWrapper>
        )) ||
        (processStatus === ProcessStatus.SUCCESS && (
          <AppSuccess
            message={t("youBoughtBonus")}
            extraMessage={t("addedToYourAccount", {
              bonusName: bonusItem.title,
            }).replace(/"/g, '')}
          >
            {hashCreated && <HashCopy hash={hashCreated} />}
            <ButtonStyled
              size={ButtonSize.XL}
              onClick={() => {
                setProcessStatus(ProcessStatus.CARDS_LIST);
                setOpenItem(null);
              }}
              fullWidth={true}
            >
              <Text
                size={TextSize.L}
                color={Color.SECONDARY}
                weight={TextWeight.BOLD}
              >
                {t("ok")}
              </Text>
            </ButtonStyled>
          </AppSuccess>
        )) ||
        (processStatus === ProcessStatus.WAITING_CONFIRMATION && (
          <AppLoading message={t("purchaseInProgress")} fullHeight={true}>
            <TextSecond size={TextSize.M} color={Color.SECONDARY}>
              {t("addedToYourAccount", { bonusName: bonusItem.title })}
            </TextSecond>

            {hashCreated && <HashCopy hash={hashCreated} />}
            <ButtonStyled
              buttonType={ButtonType.WHITE_BORDER}
              size={ButtonSize.XL}
              onClick={() => {
                setProcessStatus(ProcessStatus.CARDS_LIST);
                setOpenItem(null);
              }}
              fullWidth={true}
            >
              <Text
                size={TextSize.L}
                color={Color.SECONDARY}
                weight={TextWeight.BOLD}
              >
                {t("backToWidget")}
              </Text>
            </ButtonStyled>
          </AppLoading>
        )) ||
        (processStatus === ProcessStatus.ERROR && (
          <AppError
            errorMessage={t("error")}
            extraMessage={t("errorCreatingTransaction")}
          >
            <ButtonStyled
              buttonType={ButtonType.WHITE_BORDER}
              size={ButtonSize.XL}
              onClick={() => {
                setProcessStatus(ProcessStatus.CARDS_LIST);
                setOpenItem(null);
              }}
              fullWidth={true}
            >
              <Text
                size={TextSize.L}
                color={Color.SECONDARY}
                weight={TextWeight.BOLD}
              >
                {t("backToWidget")}
              </Text>
            </ButtonStyled>
          </AppError>
        )) ||
        (processStatus === ProcessStatus.CANCEL && (
          <CancelTx
            addressMemo={userInfo?.addressMemo}
            selectMethod={() => {
              if (!userInfo) return;
              if (userInfo?.addressMemo === WalletTypeEnum.COINBASE) {
                if (!account) {
                  activate(connectors.coinbaseWallet).then(() => {
                    setToggleSign3(true);
                  });
                } else {
                  signPermissionCoinbase(bonusItem);
                }
              } else {
                signPermission(bonusItem);
              }
            }}
            setInitProcessStatus={() => {
              setProcessStatus(ProcessStatus.CARDS_LIST);
              setOpenItem(null);
            }}
          />
        )) ||
        (processStatus === ProcessStatus.INSTALL_METAMASK && (
          <ActionRequered
            actionMessage={t("metamaskOffline")}
            extraMessage={t("metamaskOfflineText")}
            image={InstallMetamask}
            imageSize={212}
            height={"440px"}
          >
            <a
              style={{ textDecoration: "none", width: "100%" }}
              href="https://metamask.io/download/"
              target="_blank"
              rel="noreferrer"
            >
              <Button size={ButtonSize.XL} fullWidth={true}>
                <Text
                  size={TextSize.L}
                  color={Color.SECONDARY}
                  weight={TextWeight.BOLD}
                >
                  {t("installMetamask")}
                </Text>
              </Button>
            </a>
            <Button
              buttonType={ButtonType.WHITE_BORDER}
              size={ButtonSize.XL}
              onClick={() => {
                setProcessStatus(ProcessStatus.CARDS_LIST);
              }}
              fullWidth={true}
            >
              <Text
                size={TextSize.L}
                color={Color.SECONDARY}
                weight={TextWeight.BOLD}
              >
                {t("backToWidget")}
              </Text>
            </Button>
          </ActionRequered>
        )) ||
        (processStatus === ProcessStatus.INCORRECT_ADDRESS && (
          <IncorrectAddressAction
            callback={async () => {
              signPermission(bonusItem);
            }}
          />
        ))}
    </>
  );
}
