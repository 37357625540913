import rocket from './rocket';
import settings from './settings';
import close from './close';
import success from './success';
import successSmall from './successSmall';
import loader from './loader';
import error from './error';
import arrow from './arrow';
import shevron from './shevron';
import lightning from './lightning';
import visible from './visible';
import visibleNot from './visibleNot';
import cross from './cross';
import checkmarkChecked from './checkmarkChecked';
import checkmarkPartial from './checkmarkPartial';
import editPencil from './editPencil';
import editCancel from './editCancel';

import editSubmit from './editSubmit';
import chevronDown from './chevronDown';
import wallet from './wallet';
import rewind from './rewind';
import domino from './domino';
import arrows from './arrows';
import lock from './lock';
import question from './question';
import shevron_next from './shevron_next';
import back from './back';
import copy from './copy';
import copy2 from './copy2';
import lockExchange from './lock-exchage';
import increase from './increase';
import claimPlus from './claimPlus';
import history from './history';
import warning from './warning';
import exclamation from './exclamation';
import walletConnect from './walletConnect';
import chevronLeft from './chevronLeft';
import chevronRight from './chevronRight';
import coinbase from './coinbase';
import trust from './trust';
import metamask from './metamask';
import zerion from './zerion';
import Walletsettings from './Walletsettings';

const svgs = {
    rocket,
    settings,
    close,
    success,
    loader,
    error,
    arrow,
    shevron,
    lightning,
    visible,
    visibleNot,
    cross,
    checkmarkChecked,
    checkmarkPartial,
    editCancel,
    editSubmit,
    editPencil,
    chevronDown,
    wallet,
    question,
    lock,
    arrows,
    domino,
    rewind,
    shevron_next,
    back,
    copy,
    copy2,
    lockExchange,
    increase,
    claimPlus,
    successSmall,
    history,
    warning,
    exclamation,
    walletConnect,
    chevronRight,
    chevronLeft,
    coinbase,
    trust,
    metamask,
    zerion,
    Walletsettings
};

export default svgs;