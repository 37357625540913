import React, { useEffect, useState } from "react";
import { motion } from "framer-motion";
import styled from "styled-components";
import { useAppSelector } from "../../app/hooks";
import { Color } from "../../constants/ColorEnum";
import { IconType } from "../../constants/IconType";
import { RoutesTypes } from "../../constants/RoutesTypes";
import TextAlign from "../../constants/TextAlign";
import { TextSize } from "../../constants/TextSize";
import { TextWeight } from "../../constants/TextWeight";
import { DECIMALS_CONST } from "../../features/BuyBonuses/components/Exchange";
import { HistoryLine } from "../../features/cashback/components/HIstoryLine";
import { SUCCESS_MESSAGE_PROCESSING } from "../../features/cashback/redux/userInfo.slice";
import { useCustomTranslation } from "../../features/locale/hooks/useCustomTranslation";
import { formatPriceNumberWithCurrency } from "../../utils/utils";
import { useNavigateWithSearchParams } from "../hooks/useNavigateWithSearchParams";
import { Button, ButtonSize, ButtonType } from "./Button";
import { Icon } from "./Icon";
import { Text } from "./Text";
import { BalanceLoader } from "./BalanceLoader";
import { DoubleCurrencyWithAnimatedIncrease } from "./DoubleCurrencyWithAnimatedIncrease";
import { SubmitTxMessage } from "./SubmitTxMessage";

const InfoBlock = styled.div<{
  yelllowBorder?: boolean;
  headerCollapsed: boolean;
}>`
  position: relative;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: ${({ headerCollapsed }) =>
    headerCollapsed ? `8px 16px` : "12px 16px 16px 16px"};
  width: 100%;

  background: rgba(90, 171, 229, 0.05);
  border: 1px solid
    ${({ yelllowBorder }) =>
      yelllowBorder ? `${Color.WARNING_YELLOW40}` : "rgba(90, 171, 229, 0.1)"};
  border-radius: 8px;
  cursor: pointer;

  ::after {
    content: "";
    background-color: ${Color.LINE_BALANCE};
    width: 48px;

    position: absolute;
    bottom: 3px;
    left: calc(50% - 24px);
    height: 1px;
  }
`;

const IconRewind = styled(Icon)`
  margin-right: 8px;

  svg path {
    fill: ${Color.SECONDARY};
  }
`;

const BalanceWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const BalanceLine = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  justify-content: space-between;
`;

const RwdWrap = styled.div`
  display: flex;
  align-items: center;
`;

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  max-width: 170px;
`;

const IconYellow = styled(Icon)`
  position: absolute;
  top: 0px;
  left: 0px;

  svg {
    width: 10px;
    height: 10px;

    path {
      fill-opacity: 0.6;
    }
  }
`;

const IconYellowBig = styled(Icon)`
  margin-right: 4px;

  svg {
    width: 16px;
    height: 16px;

    path {
      fill-opacity: 1;
    }
  }
`;

const TextWithMarginTop = styled(Text)`
  margin-top: 12px;
  line-height: 16px;
  width: 100%;
  margin-right: auto;
`;

const WalletNotConnected = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 0px;
`;

const ButtonWithMargin = styled(Button)`
  margin-top: 8px;
`;

const TextFWD = styled(Text)<{ lineHeight: string }>`
  line-height: ${({ lineHeight }) => `${lineHeight}`};
`;

const ShowHead = styled.div<{ headerCollapsed: boolean }>`
  opacity: ${({ headerCollapsed }) => (headerCollapsed ? "0" : "1")};
`;

const BadgeWrap = styled.div<{ headerCollapsed: boolean }>`
  position: absolute;
  right: 80px;
  bottom: -4px;
`;

const DoubleCurrencyWithMargin = styled.div`
  margin-left: auto;
`;

const NotConnectedWrap = styled.div`
  display: flex;
  align-items: center;
`;

const BalanceLoaderWrapper = styled.div<{ headerCollapsed: boolean }>`
  margin-top: ${({ headerCollapsed }) =>
    headerCollapsed ? "0px !important" : "8px"};
`;

export interface SimpleBalanceProps {
  headerCollapsed: boolean;
}

export const SimpleBalance2 = ({ headerCollapsed }: SimpleBalanceProps) => {
  const { t } = useCustomTranslation();
  const balanceIsPending = useAppSelector(
    (state) => state.userInfo.balanceIsPending
  );

  const [balanceIsPendingWith5000, setBalanceIsPendingWith5000] =
    useState(false);

  useEffect(() => {
    if (balanceIsPending) {
      setBalanceIsPendingWith5000(balanceIsPending);
    } else {
      const timeout = setTimeout(() => {
        // Update the value after the timeout
        setBalanceIsPendingWith5000(!!balanceIsPending);
      }, SUCCESS_MESSAGE_PROCESSING);

      return () => clearTimeout(timeout);
    }
  }, [balanceIsPending]);

  const userInfo = useAppSelector((state) => state.userInfo.userInfo);
  const showSubmiTxMessage = useAppSelector(state => state.userInfo.showSubmiTxMessage);
  const { navigateWithSearchParams } = useNavigateWithSearchParams();

  return (
    <InfoBlock
      yelllowBorder={!userInfo?.address}
      headerCollapsed={headerCollapsed}
    >
      {!userInfo?.address && headerCollapsed && (
        <IconYellow color={Color.WARNING_YELLOW} icon={IconType.exclamation} />
      )}

      {/* {whiteListForHistory.find((el) => el === userInfo?.userId) && ( */}
      <motion.div
        initial={false}
        style={{ width: "100%" }}
        animate={headerCollapsed ? "collapsed" : "expanded"}
        variants={{
          collapsed: { opacity: 0, height: "0px", marginBottom: 0 },
          expanded: {
            opacity: 1,
            height: "100%",
            marginBottom: userInfo?.address ? "8px" : "14px",
          },
        }}
        transition={{
          duration: 0.3,
          ease: "easeInOut",
        }}
      >
        <ShowHead headerCollapsed={headerCollapsed}>
          {userInfo?.address ? (
            <HistoryLine />
          ) : (
            <WalletNotConnected>
              <NotConnectedWrap>
                <IconYellowBig
                  color={Color.WARNING_YELLOW}
                  icon={IconType.exclamation}
                  size={18}
                />
                <Text size={TextSize.XS} color={Color.WARNING_YELLOW80}>
                  {t("walletIsNotConnected")}
                </Text>
              </NotConnectedWrap>
              <Button
                onClick={() => {
                  navigateWithSearchParams(
                    {},
                    RoutesTypes.historyCashbackFirst
                  );
                }}
                distance={"4px"}
                customMaxWidth={76}
                buttonType={ButtonType.EMPTY}
                size={ButtonSize.M}
                icon={IconType.history}
              >
                {t("history")}
              </Button>
            </WalletNotConnected>
          )}
        </ShowHead>
      </motion.div>

      {/* } */}
      <BalanceWrapper>
        <BalanceLine>
          <Container>
            <RwdWrap>
              <motion.div
                initial={true}
                animate={headerCollapsed ? "collapsed" : "expanded"}
                variants={{
                  collapsed: { height: "20px" },
                  expanded: { height: "24px" },
                }}
                transition={{
                  duration: 0.3,
                }}
              >
                <IconRewind
                  icon={IconType.rewind}
                  size={headerCollapsed ? 20 : 24}
                  color={Color.BUTTON_BLUE}
                ></IconRewind>
              </motion.div>
              <TextFWD
                size={headerCollapsed ? TextSize.M : TextSize.L}
                weight={
                  headerCollapsed ? TextWeight.MEDIUM : TextWeight.SEMIBOLD
                }
                color={Color.SECONDARY}
                lineHeight={headerCollapsed ? "24px" : "28px"}
              >
                FWD
              </TextFWD>
            </RwdWrap>
            {(!balanceIsPendingWith5000 && !showSubmiTxMessage) && (
              <motion.div
                initial={true}
                animate={headerCollapsed ? "collapsed" : "expanded"}
                variants={{
                  collapsed: { opacity: 0, height: "0px", y: -15 },
                  expanded: { opacity: 1, height: "100%", y: 0 },
                }}
                transition={{
                  duration: 0.3,
                }}
              >
                <TextWithMarginTop
                  size={TextSize.XS}
                  weight={TextWeight.REGULAR}
                  color={Color.SECONDARY60}
                  align={TextAlign.LEFT}
                >
                  1 FWD = {userInfo?.poolRate} USDT
                </TextWithMarginTop>
              </motion.div>
            )}
          </Container>
          <DoubleCurrencyWithMargin>
            <DoubleCurrencyWithAnimatedIncrease
              value={
                userInfo?.address
                  ? String(
                    userInfo?.onChainBalance &&
                    userInfo?.onChainBalance / DECIMALS_CONST
                    )
                  : String(
                      userInfo?.offChainBalance &&
                        userInfo?.offChainBalance / DECIMALS_CONST
                    )
              }
              valueUsd={
                userInfo?.address
                  ? String(
                    userInfo?.usdtOnChainBalance &&
                    userInfo?.usdtOnChainBalance / DECIMALS_CONST
                    )
                  : String(
                      userInfo?.offChainBalance &&
                        (userInfo?.offChainBalance / DECIMALS_CONST) *
                          //@ts-ignore
                          userInfo?.poolRate
                    )
              }
              tooltipCollapsed={headerCollapsed}
              marginLeft={"auto"}
              TextWeightFWD={
                headerCollapsed ? TextWeight.MEDIUM : TextWeight.SEMIBOLD
              }
              margin={headerCollapsed ? 0 : 12}
              textHeightFWD={headerCollapsed ? "24px" : "28px"}
              textHeightUSD={"16px"}
              texSizeFwd={TextSize.L}
              textSizeUsd={TextSize.XS}
              hideUsd={balanceIsPendingWith5000 || showSubmiTxMessage}
            />
          </DoubleCurrencyWithMargin>
          {/* {!balanceIsPendingWith5000 && (
            <motion.div
              initial={true}
              animate={headerCollapsed ? "collapsed" : "expanded"}
              variants={{
                collapsed: { opacity: 0 },
                expanded: { opacity: 1 },
              }}
              transition={{
                duration: 0.3,
              }}
            >
              <BadgeWrap headerCollapsed={headerCollapsed}>
                <IncreaseWithTooltip />
              </BadgeWrap>
            </motion.div>
          )} */}
        </BalanceLine>

        {balanceIsPendingWith5000 ? (
          <BalanceLoaderWrapper headerCollapsed={headerCollapsed}>
            {" "}
            <BalanceLoader
              opacity={0.6}
              withoutBottom={true}
              circleSize={headerCollapsed ? 12 : undefined}
              textSize={headerCollapsed ? TextSize.XXS : undefined}
              circleMargin={headerCollapsed ? "4px" : "8px"}
            />
          </BalanceLoaderWrapper>
        ) : (showSubmiTxMessage ? <SubmitTxMessage /> :
          <>
            {!!Number(userInfo?.offChainBalance) &&
              userInfo?.address &&
              !headerCollapsed && (
                <ButtonWithMargin
                  fullWidth={true}
                  icon={IconType.claimPlus}
                  iconSize={24}
                  size={ButtonSize.XL}
                  onClick={() => {
                    // ClaimTokens();
                    navigateWithSearchParams(
                      { claimTokens: true },
                      RoutesTypes.dashboard
                    );
                  }}
                  buttonType={
                    !+`${userInfo?.offChainBalance}` || !!balanceIsPending
                      ? ButtonType.CLAIM_DISABLED
                      : ButtonType.CLAIM
                  }
                  disabled={
                    !+`${userInfo?.offChainBalance}` || !!balanceIsPending
                  }
                >
                  <>
                    <Text
                      size={TextSize.L}
                      weight={TextWeight.BOLD}
                      color={
                        !!Number(userInfo?.offChainBalance)
                          ? Color.SECONDARY
                          : Color.SECONDARY40
                      }
                    >
                      {t("claim")}{" "}
                      {formatPriceNumberWithCurrency({
                        number: `${
                          userInfo?.offChainBalance &&
                          +userInfo?.offChainBalance / 10e17
                        }`,
                      }) + " FWD"}
                    </Text>
                  </>
                </ButtonWithMargin>
              )}
          </>
        )}
      </BalanceWrapper>

      {/* {headerCollapsed && <SimpleBalanceCollapsed />} */}
    </InfoBlock>
  );
};
