import { useEffect, useMemo } from "react";
import { Outlet } from "react-router";
import styled from "styled-components";
import { useAppDispatch, useAppSelector } from "../app/hooks";
import { Color } from "../constants/ColorEnum";
import { checkTxStatus, TxStatus } from "../features/BuyBonuses/redux/actions";
import {
  getCommonInfo,
  getTxStatusBySid,
  getUserInfo,
} from "../features/cashback/redux/actions";
import {
  resetActiveTx,
  setBalanceIsPending,
  SUCCESS_MESSAGE_PROCESSING,
  TxHashEnum,
} from "../features/cashback/redux/userInfo.slice";
import { getTranslations } from "../features/locale/redux/actions";
import { inIframe, isSafari, useQueryParams } from "../utils/utils";
import { APP_CHAIN_ID } from "../features/cashback/components/etheriumClient";
import { ClientContextProvider } from "../context/ClientContext";
import { WalletConnectLayout } from "./WalletConnectLayout";
import WalletConnect from "../resources/images/rewind-not-in-iframe.png";
import { LangEnum } from "../features/locale/redux/locale.slice";
import { useDetectCoinbase } from "../shared/hooks/useDetectCoinbase";

export const defaultChainId =
  Number(process.env.REACT_APP_CHAIN_ID) || APP_CHAIN_ID;

const MainLayoutWrapper = styled.div`
  position: relative;
`;

const MainLayoutWrapperDecoration = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;
  background: linear-gradient(
      90.11deg,
      rgba(72, 14, 77, 0.5) 0.11%,
      rgba(87, 61, 221, 0.5) 20.07%,
      rgba(221, 99, 61, 0.5) 40.03%,
      rgba(221, 99, 61, 0.5) 59.99%,
      rgba(87, 61, 221, 0.5) 79.95%,
      rgba(72, 14, 77, 0.5) 99.92%
    ),
    radial-gradient(33.02% 59.44% at 50% 50%, #573ddd 0%, #180e4d 100%);
`;

const OutletWrapper = styled.div`
  margin: auto;
  border-radius: 8px;
  width: 100%;
  height: 100vh;
  min-height: 640px;
  background-color: ${Color.BACKGROUND_COLOR};
`;

const OutletWrapperNotInIframe = styled.div`
  margin: auto;
  border-radius: 8px;
  width: 400px;
  height: 640px;
  min-height: 640px;
  background-color: ${Color.BACKGROUND_COLOR};
`;

const ImageStyled = styled.img`
  position: absolute;
  left: 32px;
  bottom: 32px;
`;


export const MainLayout = () => {
  const INTERVAL_USER_INFO_REFETCH =
    Number(process.env.REACT_APP_INTERVAL_USER_INFO_REFETCH) || 30000;
  const balanceIsPending = useAppSelector(
    (state) => state.userInfo.balanceIsPending
  );
  const { sid, lang, callback, isMobile } = useQueryParams();
  const dispatch = useAppDispatch();
  useEffect(() => {
    dispatch(getUserInfo({ sid }));
    dispatch(getCommonInfo());

    let interval = setInterval(async () => {
      if (!balanceIsPending) {
        dispatch(getUserInfo({ sid }));
      }
    }, INTERVAL_USER_INFO_REFETCH);

    return () => clearInterval(interval);
  }, [sid, balanceIsPending]);

  const INTERVAL_USER_INFO_DELAY =
    Number(process.env.REACT_APP_INTERVAL_USER_DELAY) || 6000;
  const activeTxHash = useAppSelector((state) => state.userInfo.activeTxHash);
  const activeTxHashUsage = useAppSelector(
    (state) => state.userInfo.activeTxHashUsage
  );

  useEffect(() => {
    // function getTimestampInSeconds() {
    //   return Date.now();
    // }
    let interval: NodeJS.Timer | undefined;
    let i = 0;
    if (activeTxHash) {
      interval = setInterval(async () => {
        if (i <= 30) {
          let result;
          if (activeTxHashUsage === TxHashEnum.CLAIM) {
            result = await checkTxStatus(activeTxHash, 8);
          } else if (activeTxHashUsage === TxHashEnum.BUY_BONUS) {
            result = await checkTxStatus(activeTxHash, 9);
          } else if (activeTxHashUsage === TxHashEnum.EXCHANGE) {
            result = await checkTxStatus(activeTxHash, 10);
          } else {
            result = await checkTxStatus(activeTxHash);
          }

          if (result.status === TxStatus.MINED) {
            clearInterval(interval);
            setTimeout(
              () => {
                console.log('4sec pass')
                dispatch(getUserInfo({ sid })).then((res) => {
                  dispatch(
                    setBalanceIsPending({
                      status: false,
                      txHashStatus: TxStatus.MINED,
                    })
                  );
                  setTimeout(() => {
                    dispatch(resetActiveTx());

                    if (callback && isSafari()) {
                      setTimeout(() => {
                        window.location.href = callback;
                      }, 3000);
                    }
                  }, SUCCESS_MESSAGE_PROCESSING);
                });
              },
              activeTxHashUsage === TxHashEnum.CLAIM
                ? 10000
                : INTERVAL_USER_INFO_DELAY
            );
          } else if (result.status === TxStatus.FAILED) {
            dispatch(
              setBalanceIsPending({
                status: false,
                txHashStatus: TxStatus.FAILED,
              })
            );
            setTimeout(() => {
              dispatch(resetActiveTx());
            }, SUCCESS_MESSAGE_PROCESSING);
          }
          i++;
        } else {
          clearInterval(interval);
        }
      }, 20000);
    }
    return () => clearInterval(interval);
  }, [activeTxHash, activeTxHashUsage]);

  useEffect(() => {
    if (lang) {
      dispatch(getTranslations({ locale: lang || LangEnum.en }));
    }
  }, [lang]);

  useEffect(() => {
    dispatch(getTxStatusBySid({ sid }));
  }, []);

  const isInIframe = useMemo(() => inIframe(), []);

  const {isCoinbase} = useDetectCoinbase();

  return (
    <>
      {(isInIframe || isMobile === "true" || isCoinbase) ? (
        <MainLayoutWrapper>
          <ClientContextProvider>
            <WalletConnectLayout>
              <OutletWrapper>
                <Outlet></Outlet>
              </OutletWrapper>
            </WalletConnectLayout>
          </ClientContextProvider>
        </MainLayoutWrapper>
      ) : (
        <MainLayoutWrapperDecoration>
          <ClientContextProvider>
            <WalletConnectLayout>
              <OutletWrapperNotInIframe>
                <Outlet></Outlet>
              </OutletWrapperNotInIframe>
            </WalletConnectLayout>
          </ClientContextProvider>

          <ImageStyled src={WalletConnect} alt="Rewind-Protocol" />
        </MainLayoutWrapperDecoration>
      )}
    </>
  );
};
