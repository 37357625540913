import Client from "@walletconnect/sign-client";
import { PairingTypes, SessionTypes } from "@walletconnect/types";
import { Web3Modal } from "@web3modal/standalone";

// import { PublicKey } from "@solana/web3.js";
import {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";

import { getAppMetadata, getSdkError } from "@walletconnect/utils";
import {
  DEFAULT_APP_METADATA,
  DEFAULT_LOGGER,
  DEFAULT_PROJECT_ID,
  DEFAULT_RELAY_URL,
} from "../constants";
import { AccountBalances, apiGetAccountBalance } from "../helpers";
import {
  getOptionalNamespaces,
  getRequiredNamespaces,
} from "../helpers/namespaces";
import { useAppSelector } from "../app/hooks";
import { RoutesTypes } from "../constants/RoutesTypes";
import { useNavigateWithSearchParams } from "../shared/hooks/useNavigateWithSearchParams";
import { WalletTypeEnum } from "../features/cashback/redux/userInfo.slice";
import { useWalletConnectionType } from "../shared/hooks/useWalletConnectionType";


export const DEFAULT_CHAIN = 'eip155:137'
/**
 * Types
 */
interface IContext {
  client: Client | undefined;
  session: SessionTypes.Struct | undefined;
  connect: (pairing?: { topic: string }) => Promise<void>;
  disconnect: () => Promise<void>;
  isInitializing: boolean;
  chains: string[];
  relayerRegion: string;
  pairings: PairingTypes.Struct[];
  accounts: string[];
  // solanaPublicKeys?: Record<string, PublicKey>;
  balances: AccountBalances;
  isFetchingBalances: boolean;
  setChains: any;
  setRelayerRegion: any;
  origin: string;
}

/**
 * Context
 */
export const ClientContext = createContext<IContext>({} as IContext);

/**
 * Web3Modal Config
 */
const web3Modal = new Web3Modal({
  projectId: DEFAULT_PROJECT_ID,
  themeMode: "light",
  walletConnectVersion: 2,
  explorerRecommendedWalletIds: [
    'c57ca95b47569778a828d19178114f4db188b89b763c899ba0be274e97267d96',
    '4622a2b2d6af1c9844944291e5e7351a6aa24cd7b23099efac1b2fd875da31a0',
    'ecc4036f814562b41a5268adc86270fba1365471402006302e70169465b7ac18',
  ],
  explorerExcludedWalletIds: [
    'c57ca95b47569778a828d19178114f4db188b89b763c899ba0be274e97267d96',
    '4622a2b2d6af1c9844944291e5e7351a6aa24cd7b23099efac1b2fd875da31a0',
    'ecc4036f814562b41a5268adc86270fba1365471402006302e70169465b7ac18',
  ]
});

/**
 * Provider
 */
export function ClientContextProvider({
  children,
}: {
  children: ReactNode | ReactNode[];
}) {
  const [client, setClient] = useState<Client>();
  const [pairings, setPairings] = useState<PairingTypes.Struct[]>([]);
  const [session, setSession] = useState<SessionTypes.Struct>();

  const [isFetchingBalances, setIsFetchingBalances] = useState(false);
  const [isInitializing, setIsInitializing] = useState(false);
  const prevRelayerValue = useRef<string>("");

  const [balances, setBalances] = useState<AccountBalances>({});
  const [accounts, setAccounts] = useState<string[]>([]);
  // const [solanaPublicKeys, setSolanaPublicKeys] =
  //   useState<Record<string, PublicKey>>();
  const [chains, setChains] = useState<string[]>([]);
  const [relayerRegion, setRelayerRegion] = useState<string>(
    DEFAULT_RELAY_URL!
  );
  const [origin, setOrigin] = useState<string>(getAppMetadata().url);
  const reset = () => {
    setSession(undefined);
    setBalances({});
    setAccounts([]);
    setChains([]);
    setRelayerRegion(DEFAULT_RELAY_URL!);
  };

  const userInfo = useAppSelector((state) => state.userInfo.userInfo);
  const getAccountBalances = async (_accounts: string[]) => {
    setIsFetchingBalances(true);
    try {
      const arr = await Promise.all(
        _accounts.map(async (account) => {
          const [namespace, reference, address] = account.split(":");
          const chainId = `${namespace}:${reference}`;
          const assets = await apiGetAccountBalance(address, chainId);

          return { account, assets: [assets] };
        })
      );

      const balances: AccountBalances = {};
      arr.forEach(({ account, assets }) => {
        balances[account] = assets;
      });
      setBalances(balances);
    } catch (e) {
      console.error(e);
    } finally {
      setIsFetchingBalances(false);
    }
  };

  const deleteUnsupportedWallets = () => {
    const attributeNames: string[] = ['Zerion', 'MetaMask', 'Trust Wallet'];
  
    //@ts-ignore
    const container: Element | null = document.querySelector('w3m-modal')
      ?.shadowRoot.querySelector('w3m-modal-router')
      ?.shadowRoot.querySelector('w3m-wallet-explorer-view')
      ?.shadowRoot.querySelector('w3m-modal-content')!;
  
    const elements: NodeListOf<Element> = container?.querySelectorAll('w3m-wallet-button') || document.querySelectorAll('w3m-wallet-button');
  
    Array.from(elements).forEach((element: Element) => {
      const nameAttribute: string | null = element.getAttribute('name');
      if (!(nameAttribute && attributeNames.includes(nameAttribute))) {
        (element as HTMLElement).style.display = 'none';
      }
    });
  };

  const runFunctionWithInterval = (
    func: () => void,
    interval: number,
    count: number
  ): void => {
    let intervalCount = 0;
    const intervalId = setInterval(() => {
      func();
      intervalCount++;
  
      if (intervalCount === count) {
        clearInterval(intervalId);
      }
    }, interval);
  };


  

  const onSessionConnected = useCallback(
    async (_session: SessionTypes.Struct) => {
      const allNamespaceAccounts = Object.values(_session.namespaces)
        .map((namespace) => (namespace.accounts))
        
        .flat();
      const allNamespaceChains = Object.keys(_session.namespaces);

      setSession(_session);
      setChains(allNamespaceChains);
      setAccounts(allNamespaceAccounts.map(acc => acc.replace(/^eip155:137:/, '')));
      // setSolanaPublicKeys(getPublicKeysFromAccounts(allNamespaceAccounts));
      console.log('ON_SESSION_CONNECT!-1')
      
      await getAccountBalances(allNamespaceAccounts);
      console.log('ON_SESSION_CONNECT!-2')
    },
    []
  );
  const { navigateWithSearchParams } = useNavigateWithSearchParams();

  const connect = useCallback(
    async (pairing: any) => {
      if (walletConnectionTypeMemo !== null && walletConnectionTypeMemo === WalletTypeEnum.COINBASE) return;

      if (typeof client === "undefined") {
        throw new Error("WalletConnect is not initialized");
      }
      console.log("connect, pairing topic is:", pairing?.topic);
      try {
        const requiredNamespaces = getRequiredNamespaces([DEFAULT_CHAIN]);
        console.log(
          "requiredNamespaces config for connect:",
          requiredNamespaces
        );
        const optionalNamespaces = getOptionalNamespaces(chains);
        console.log(
          "optionalNamespaces config for connect:",
          optionalNamespaces
        );

        const { uri, approval } = await client.connect({
          pairingTopic: pairing?.topic,
          requiredNamespaces,
          optionalNamespaces,
        });

        // Call the function with your parameters
        // runFunctionWithInterval(deleteUnsupportedWallets, 1000, 100);

        // Open QRCode modal if a URI was returned (i.e. we're not connecting an existing pairing).
        if (uri) {
          // Create a flat array of all requested chains across namespaces.
          const standaloneChains = Object.values(requiredNamespaces)
            .map((namespace) => namespace.chains)
            .flat() as string[];

          web3Modal.openModal({ uri, standaloneChains });
        }

        const session = await approval();
        console.log("Established session:", session);
        await onSessionConnected(session);

        const account = session.namespaces.eip155.accounts[0].replace(/^eip155:137:/, '')

        console.log(account, 'ACCOUNT')
        

        if (userInfo?.address && (userInfo?.address?.toLowerCase() !== account?.toLocaleLowerCase())) {
          navigateWithSearchParams({}, RoutesTypes.switchWallet);
        }

        // Update known pairings after session is connected.
        // setPairings(client.pairing.getAll({ active: true }));
      } catch (e) {
        console.error(e);
        // ignore rejection
      } finally {
        // close modal in case it was open
        web3Modal.closeModal();
      }
    },
    [chains, client, onSessionConnected]
  );

  const disconnect = useCallback(async () => {
    if (typeof client === "undefined") {
      throw new Error("WalletConnect is not initialized");
    }
    if (typeof session === "undefined") {
      throw new Error("Session is not connected");
    }

    try {
      await client.disconnect({
        topic: session.topic,
        reason: getSdkError("USER_DISCONNECTED"),
      });
    } catch (error) {
      console.error("SignClient.disconnect failed:", error);
    } finally {
      // Reset app state after disconnect.
      reset();
    }
  }, [client, session]);

  const { walletConnectionTypeMemo } = useWalletConnectionType();

  const _subscribeToEvents = useCallback(
    async (_client: Client) => {
      if (walletConnectionTypeMemo !== null && walletConnectionTypeMemo === WalletTypeEnum.COINBASE) return;

      if (typeof _client === "undefined") {
        throw new Error("WalletConnect is not initialized");
      }

      _client.on("session_ping", (args) => {
        console.log("EVENT", "session_ping", args);
      });

      _client.on("session_event", (args) => {
        console.log("EVENT", "session_event", args);
      });

      _client.on("session_update", ({ topic, params }) => {
        console.log("EVENT", "session_update", { topic, params });
        const { namespaces } = params;
        const _session = _client.session.get(topic);
        const updatedSession = { ..._session, namespaces };
        onSessionConnected(updatedSession);
      });

      _client.on("session_delete", () => {
        console.log("EVENT", "session_delete");
        reset();
      });
    },
    [onSessionConnected, walletConnectionTypeMemo]
  );

  const _checkPersistedState = useCallback(
    async (_client: Client) => {
      if (walletConnectionTypeMemo !== null && walletConnectionTypeMemo === WalletTypeEnum.COINBASE) return;

      if (typeof _client === "undefined") {
        throw new Error("WalletConnect is not initialized");
      }
      // populates existing pairings to state
      setPairings(_client.pairing.getAll({ active: true }));
      console.log(
        "RESTORED PAIRINGS: ",
        _client.pairing.getAll({ active: true })
      );

      if (typeof session !== "undefined") return;
      // populates (the last) existing session to state
      if (_client.session.length) {
        const lastKeyIndex = _client.session.keys.length - 1;
        const _session = _client.session.get(
          _client.session.keys[lastKeyIndex]
        );
        console.log("RESTORED SESSION:", _session);
        await onSessionConnected(_session);
        return _session;
      }
    },
    [session, onSessionConnected, walletConnectionTypeMemo]
  );

  const _logClientId = useCallback(async (_client: Client) => {
    if (typeof _client === "undefined") {
      throw new Error("WalletConnect is not initialized");
    }
    try {
      const clientId = await _client.core.crypto.getClientId();
      console.log("WalletConnect ClientID: ", clientId);
      localStorage.setItem("WALLETCONNECT_CLIENT_ID", clientId);
    } catch (error) {
      console.error(
        "Failed to set WalletConnect clientId in localStorage: ",
        error
      );
    }
  }, []);

  const createClient = useCallback(async () => {
    if (walletConnectionTypeMemo === WalletTypeEnum.COINBASE) return;

    try {
      setIsInitializing(true);
      const claimedOrigin =
        localStorage.getItem("wallet_connect_dapp_origin") || origin;
      const _client = await Client.init({
        logger: DEFAULT_LOGGER,
        relayUrl: relayerRegion,
        projectId: DEFAULT_PROJECT_ID,
        metadata: {
          ...(getAppMetadata() || DEFAULT_APP_METADATA),
          url: claimedOrigin,
          verifyUrl:
            claimedOrigin === "unknown"
              ? "http://non-existent-url"
              : DEFAULT_APP_METADATA.verifyUrl, // simulates `UNKNOWN` verify context
        },
      });

      setClient(_client);
      setOrigin(_client.metadata.url);
      prevRelayerValue.current = relayerRegion;
      await _subscribeToEvents(_client);
      await _checkPersistedState(_client);
      await _logClientId(_client);
    } catch (err) {
      throw err;
    } finally {
      setIsInitializing(false);
    }
  }, [
    _checkPersistedState,
    _subscribeToEvents,
    _logClientId,
    relayerRegion,
    origin,
    walletConnectionTypeMemo,
  ]);

  useEffect(() => {
    if (!client) {
      createClient();
    } else if (prevRelayerValue.current !== relayerRegion) {
      client.core.relayer.restartTransport(relayerRegion);
      prevRelayerValue.current = relayerRegion;
    }
  }, [createClient, relayerRegion, client]);

  const value = useMemo(
    () => ({
      pairings,
      isInitializing,
      balances,
      isFetchingBalances,
      accounts,
      // solanaPublicKeys,
      chains,
      relayerRegion,
      client,
      session,
      connect,
      disconnect,
      setChains,
      setRelayerRegion,
      origin,
    }),
    [
      pairings,
      isInitializing,
      balances,
      isFetchingBalances,
      accounts,
      // solanaPublicKeys,
      chains,
      relayerRegion,
      client,
      session,
      connect,
      disconnect,
      setChains,
      setRelayerRegion,
      origin,
    ]
  );

  return (
    <ClientContext.Provider
      value={{
        ...value,
      }}
    >
      {children}
    </ClientContext.Provider>
  );
}

export function useWalletConnectClient() {
  const context = useContext(ClientContext);
  if (context === undefined) {
    throw new Error(
      "useWalletConnectClient must be used within a ClientContextProvider"
    );
  }
  return context;
}
