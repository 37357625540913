import HiddenTooltip from "../../../shared/components/HiddenTooltip";
import styled from "styled-components";
import { Text } from "../../../shared/components/Text";
import { Color } from "../../../constants/ColorEnum";
import { useCustomTranslation } from "../../locale/hooks/useCustomTranslation";
import { TextSize } from "../../../constants/TextSize";
import { TextWeight } from "../../../constants/TextWeight";
import { TooltipPlacement } from "../../../shared/components/Tooltip";
import { Increase } from "../../../shared/components/Increase";
import { useAppSelector } from "../../../app/hooks";

const START_POOLRATE = 0.0001;

const IncreaseButton = styled.div`

  height: 24px;
  box-sizing: border-box;

  padding: 4px;
  border-radius: 4px;
  background-color: ${Color.SECONDARY05};
  cursor: pointer;
  display: flex;
  align-items: center;
`;


const TooltipContent = styled(Text)`
  text-align: center;
  width: 157px;
  padding: 12px;
  border-radius: 4px;
  gap: 12px;
  background: rgba(10, 12, 20, 0.8);
`;

export const IncreaseWithTooltip = () => {
  const { t } = useCustomTranslation();
  const commonInfo = useAppSelector(state => state.userInfo.commonInfo);

  return (
    <IncreaseButton>
      <HiddenTooltip
        content={
          <TooltipContent
            color={Color.SECONDARY}
            size={TextSize.XS}
            weight={TextWeight.MEDIUM}
          >
            {t("poolRateIncreaseText")}
          </TooltipContent>
        }
        timerDuration={3000}
        position={TooltipPlacement.LEFT}
      >
        <Increase
          value={
            ((Number(commonInfo.info?.poolRate) - START_POOLRATE) / START_POOLRATE) *
            100
          }
        />
      </HiddenTooltip>
    </IncreaseButton>
  );
};
