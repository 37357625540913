import React, { useEffect, useMemo, useRef, useState } from "react";
import {
  Button,
  ButtonSize,
  ButtonType,
} from "../../../shared/components/Button";
import styled from "styled-components";
import { Color } from "../../../constants/ColorEnum";
import { IconType } from "../../../constants/IconType";
import { Icon } from "../../../shared/components/Icon";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { Text } from "../../../shared/components/Text";
import { TextSize } from "../../../constants/TextSize";
import { TextWeight } from "../../../constants/TextWeight";
import {
  formatPriceNumberWithCurrency,
  useQueryParams,
} from "../../../utils/utils";
import axios from "axios";
import { AppLoading } from "../../../shared/components/AppLoader";
import { AppSuccess } from "../../../shared/components/AppSuccess";
import { AppError } from "../../../shared/components/AppError";
import { TxStatus } from "../../BuyBonuses/redux/actions";
import { HashCopy } from "../../../shared/components/HashCopy";
import {
  setBalanceIsPending,
  SUCCESS_MESSAGE_PROCESSING,
  TxHashEnum,
} from "../redux/userInfo.slice";
import { DoubleCurrency } from "../../../shared/components/DoubleCurrency";
import { BalanceLoader } from "../../../shared/components/BalanceLoader";
import { useCustomTranslation } from "../../locale/hooks/useCustomTranslation";
import { DECIMALS_CONST } from "../../BuyBonuses/components/Exchange";
import TextAlign from "../../../constants/TextAlign";
import { getEventJournal } from "../../History/redux/actions";
import { resetLastRecordWithStatusTwo } from "../../History/redux/history.slice";
import { HistoryLine } from "./HIstoryLine";
import { useEffectSkipFirst } from "../../../shared/hooks/useEffectSkipFirst";
import { SubmitTxMessage } from "../../../shared/components/SubmitTxMessage";

export const whiteListForHistory = [
  "account19",
  "accountMetamask24",
  "1f788a88-3e67-4d5f-bdd3-3811410796ec",

  "48dd8758-0487-4e47-9421-8a777618f2f6",

  "0baec8a2-4399-4a78-b436-c0338c2fe678",

  "80f50ff4-7f6d-42bd-816e-2e534fbfa8ac",
  "f091055b-a494-4276-b8a0-8ea0399c1826",
  "TestHistory",
  "123",
  "0123",
  "account1a",
  "account111a",
  "09d44fd0-bde6-441d-8924-d67f22beb46c",
  "daf7d0eb-a0cb-4fdc-a05e-00feb426749a",
  "cdbb1a5c-3a45-4938-a7bb-fa6f0644dd6a",
  // "2483141f-93f6-42dc-be1c-451f2db8ae28",
  "48dd8758-0487-4e47-9421-8a777618f2f6",
  "34dc7be1-543e-4f48-8451-518ecdd75959",
  "58321320-14ff-4467-93c1-8298ea384a4c",


  "7dab467d-6d5d-492d-90f7-62b08dce258d",
  "9e7f299b-f684-47d2-8561-79644a0aaa4e",
  "a34e964b-04a1-4b56-81e0-91f6c4ed0f6a",
  "62c42824-484c-4ff7-aa54-777f5f5cbb68",
  "15ec1ff2-5485-4e89-ae4a-afa977e46af0",
  "3f0ac5a6-023d-42f5-81ee-165950c26a9a",
  "a5403f00-235e-4f97-972f-622140bdea47",
  "95dcb60b-9024-4a24-9d31-d3a37d29e807",
  "849ae08a-9f59-47df-95b6-a743ae4a17d3",
  "b994a869-8502-4c9c-8367-a0b313dbd147",


  "1613e16f-c003-4cec-acd8-c54abcc92b38",
  "dea31fde-d0bd-46e2-ab31-f99d0d8668cf",




  "2483141f-93f6-42dc-be1c-451f2db8ae28",
  "3563b226-9abf-4c3b-b958-53382c12147f",
  "1f788a88-3e67-4d5f-bdd3-3811410796ec"
];

const InfoBlock = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 16px;
  width: 100%;
  background: rgba(90, 171, 229, 0.05);
  border: 1px solid rgba(90, 171, 229, 0.1);
  border-radius: 8px;
`;

const IconRewind = styled(Icon)`
  margin-right: 8px;

  svg path {
    fill: ${Color.SECONDARY};
  }
`;

const BalanceWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 12px;
`;

const BalanceLine = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-top: 8px;
`;

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  max-width: 170px;
`;

const RwdWrap = styled.div`
  display: flex;
  align-items: center;
`;

const ButtonStyled = styled(Button)`
  margin-top: 16px;
`;

const TextSecond = styled(Text)`
  line-height: 28px;
`;

const TextWithMarginTop = styled(Text)`
  margin-top: 12px;
`;

const TextWithMarginTop2 = styled(Text)`
  margin-top: 12px;
`;

export enum ProcessStatus {
  INITIAL = "INITIAL",
  LOADING = "LOADING",
  SUCCESS = "SUCCESS",
  ERROR = "ERROR",
  WAITING_CONFIRMATION = "WAITING_CONFIRMATION",
}

export const ClaimTokens = ({
  setShowNavLinks,
  hideExchange,
}: {
  setShowNavLinks: (status: boolean) => void;
  hideExchange?: (state: boolean) => void;
}) => {
  const [processStatus, setProcessStatus] = useState<ProcessStatus>(
    ProcessStatus.INITIAL
  );
  const { sid, claimTokens } = useQueryParams();
  const userInfo = useAppSelector((state) => state.userInfo.userInfo);
  const balanceIsPending = useAppSelector(
    (state) => state.userInfo.balanceIsPending
  );
  const { timeLeft, amount: tokensExpiredAmount } = useAppSelector(
    (state) => state.history.lastRecordWithStatusTwo
  );

  const tokenExpiredDays = useMemo(
    () => timeLeft && Math.floor(Number(timeLeft) / 86400000),
    [timeLeft]
  );

  const [balanceIsPendingWith5000, setBalanceIsPendingWith5000] =
    useState(false);

  useEffect(() => {
    if (balanceIsPending) {
      setBalanceIsPendingWith5000(balanceIsPending);
    } else {
      const timeout = setTimeout(() => {
        // Update the value after the timeout
        setBalanceIsPendingWith5000(!!balanceIsPending);
      }, SUCCESS_MESSAGE_PROCESSING);

      return () => clearTimeout(timeout);
    }
  }, [balanceIsPending]);

  const showSubmiTxMessage = useAppSelector(state => state.userInfo.showSubmiTxMessage)

  const dispatch = useAppDispatch();
  const [hashCreated, setHashCreated] = useState();
  const [error, setError] = useState("");
  const { t } = useCustomTranslation();

  useEffect(() => {
    setShowNavLinks(processStatus === ProcessStatus.INITIAL);
  }, [processStatus]);

  const ClaimTokens = async () => {
    hideExchange && hideExchange(true);
    try {
      setProcessStatus(ProcessStatus.LOADING);
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/clients/claim-client-tokens`,
        {
          sid,
        }
      );

      if (response.data.hash) {
        setProcessStatus(ProcessStatus.WAITING_CONFIRMATION);
        setHashCreated(response.data.hash);
        dispatch(
          setBalanceIsPending({
            status: true,
            txHash: response.data.hash,
            txString: t('tokensClaimed'),
          })
        );
        dispatch(resetLastRecordWithStatusTwo());
      }
    } catch (err) {
      console.log(err, "ERROR");
      setProcessStatus(ProcessStatus.ERROR);
      //@ts-ignore
      setError(err.response.data.message);

      console.error(err);
    }
  };

  //TO DO - separate hook useTxHashSpy
  const activeTxHash = useAppSelector((state) => state.userInfo.activeTxHash);
  const activeTxHashStatus = useAppSelector(
    (state) => state.userInfo.activeTxHashStatus
  );
  const activeTxHashUsage = useAppSelector(
    (state) => state.userInfo.activeTxHashUsage
  );
  useEffect(() => {
    dispatch(getEventJournal({ sid, page: 1, limit: 9999 }));
  }, []);

  useEffect(() => {
    if (processStatus !== ProcessStatus.INITIAL) {
      if (
        activeTxHash &&
        activeTxHashStatus === TxStatus.MINED &&
        activeTxHashUsage === TxHashEnum.CLAIM
      ) {
        setProcessStatus(ProcessStatus.SUCCESS);
      }

      if (
        activeTxHashStatus === TxStatus.FAILED &&
        activeTxHashUsage === TxHashEnum.CLAIM
      ) {
        setProcessStatus(ProcessStatus.ERROR);
        setError("Transaction Error");
      }
    }

    if (
      processStatus === ProcessStatus.SUCCESS ||
      processStatus === ProcessStatus.ERROR
    ) {
      if (!activeTxHash && !activeTxHashStatus && !activeTxHashUsage) {
        setProcessStatus(ProcessStatus.INITIAL);
      }
    }
  }, [activeTxHash, activeTxHashUsage, activeTxHashStatus]);

  useEffect(() => {
    if (processStatus === ProcessStatus.WAITING_CONFIRMATION && hashCreated) {
      dispatch(
        setBalanceIsPending({
          status: true,
          txHash: hashCreated,
          txHashUsage: TxHashEnum.CLAIM,
          txString:  t('tokensClaimed'),
        })
      );
    }
  }, [processStatus]);

  const increaseButtonRef = useRef<HTMLDivElement>(null);
  const [increaseButtonWidth, setIncreaseButtonWidth] = useState<number>(0);

  useEffect(() => {
    const getElementWidth = () => {
      if (increaseButtonRef.current) {
        const width = increaseButtonRef.current.offsetWidth;
        setIncreaseButtonWidth(width);
      }
    };

    getElementWidth();
    window.addEventListener("resize", getElementWidth);

    return () => {
      window.removeEventListener("resize", getElementWidth);
    };
  }, []);

  useEffectSkipFirst(() => {
    if (claimTokens === 'true') {
      ClaimTokens();
    }
  }, [claimTokens])

  return (
    <>
      {(processStatus === ProcessStatus.INITIAL && (
        <InfoBlock>
          <BalanceWrapper>
            {/* {whiteListForHistory.find((el) => el === userInfo?.userId) && ( */}
            <HistoryLine />
            {/*  )} */}
            <BalanceLine>
              <Container>
                <RwdWrap>
                  <IconRewind
                    icon={IconType.rewind}
                    size={24}
                    color={Color.BUTTON_BLUE}
                  ></IconRewind>
                  <Text
                    size={TextSize.XXL}
                    weight={TextWeight.BOLD}
                    color={Color.SECONDARY}
                  >
                    FWD
                  </Text>
                </RwdWrap>

                <TextWithMarginTop
                  size={TextSize.XS}
                  weight={TextWeight.REGULAR}
                  color={Color.SECONDARY60}
                  align={TextAlign.LEFT}
                >
                  1 FWD = {userInfo?.poolRate} USDT
                </TextWithMarginTop>
              </Container>

              <DoubleCurrency
                value={String(
                  userInfo?.onChainBalance &&
                    userInfo?.onChainBalance / DECIMALS_CONST
                )}
                valueUsd={String(
                  userInfo?.usdtOnChainBalance &&
                    userInfo?.usdtOnChainBalance / DECIMALS_CONST
                )}
                textSizeUsd={TextSize.XS}
                marginRight={`${increaseButtonWidth + 4}px`}
                withIncreaseTooltip={true}
                margin={12}
                increaseTooltipMargin={"8px"}
                marginLeft={"4px"}
              ></DoubleCurrency>

              {/* <IncreaseButtonWrap ref={increaseButtonRef}>
                <IncreaseWithTooltip />
              </IncreaseButtonWrap> */}
            </BalanceLine>
            {/* <ClaimLine>
              <Text
                size={TextSize.M}
                weight={TextWeight.REGULAR}
                color={Color.SECONDARY}
              >
                {t("availableToClaim")}
              </Text>
              {!balanceIsPending && (
                <Text
                  size={TextSize.M}
                  weight={TextWeight.BOLD}
                  color={Color.GREEN_CLAIM}
                >
                  +{" "}
                  {formatPriceNumberWithCurrency({
                    number: `${
                      userInfo?.offChainBalance &&
                      +userInfo?.offChainBalance / 10e17
                    }`,
                  })}
                </Text>
              )}
            </ClaimLine> */}
          </BalanceWrapper> 
          {balanceIsPendingWith5000 ? (
            <BalanceLoader />
          ) : (
            showSubmiTxMessage ? <SubmitTxMessage /> :
            <>
              <Button
                fullWidth={true}
                icon={IconType.claimPlus}
                iconSize={24}
                size={ButtonSize.XL}
                onClick={() => {
                  ClaimTokens();
                }}
                buttonType={
                  !+`${userInfo?.offChainBalance}` || !!balanceIsPending
                    ? ButtonType.CLAIM_DISABLED
                    : ButtonType.CLAIM
                }
                disabled={
                  !+`${userInfo?.offChainBalance}` || !!balanceIsPending
                }
              >
                <>
                  <Text
                    size={TextSize.L}
                    weight={TextWeight.BOLD}
                    color={
                      !!Number(userInfo?.offChainBalance)
                        ? Color.SECONDARY
                        : Color.SECONDARY40
                    }
                  >
                    {t("claim")}{" "}
                    {formatPriceNumberWithCurrency({
                      number: `${
                        userInfo?.offChainBalance &&
                        +userInfo?.offChainBalance / 10e17
                      }`,
                    }) + " FWD"}
                  </Text>
                </>
              </Button>
              {timeLeft && tokensExpiredAmount && (
                <TextWithMarginTop2
                  size={TextSize.XS}
                  weight={TextWeight.REGULAR}
                  color={Color.SECONDARY60}
                  align={TextAlign.CENTER}
                >
                  {" "}
                  {t("willExpireIn", {
                    fwdAmount: String(
                      formatPriceNumberWithCurrency({
                        number: String(tokensExpiredAmount),
                      }) +
                        " " +
                        "FWD"
                    ),
                    daysCount: String(tokenExpiredDays),
                  }).replace(/"/g, "")}
                  {/* {formatPriceNumberWithCurrency({
                      number: String(tokensExpiredAmount / 10e17),
                    })}{" "}
                    FWD{" "}
                    {Number(timeLeft) < 86400000
                      ? t("willExpireToday")
                      : t("willExpireIn") + " " + tokenExpiredDays}{" "}
                    {Number(tokenExpiredDays) > 1 ? t("days") : t("day")} */}
                </TextWithMarginTop2>
              )}
            </>
          )}
        </InfoBlock>
      )) ||
        (processStatus === ProcessStatus.LOADING && (
          <AppLoading message={t("loading")} fullHeight={true}></AppLoading>
        )) ||
        (processStatus === ProcessStatus.SUCCESS && (
          <AppSuccess message={ t('tokensClaimed')} extraMessage={""}>
            {hashCreated && <HashCopy hash={hashCreated} />}
            <ButtonStyled
              customHeight={48}
              size={ButtonSize.XL}
              onClick={() => {
                hideExchange && hideExchange(false);
                setProcessStatus(ProcessStatus.INITIAL);
              }}
              fullWidth={true}
            >
              <Text
                size={TextSize.L}
                color={Color.SECONDARY}
                weight={TextWeight.BOLD}
              >
                OK
              </Text>
            </ButtonStyled>
          </AppSuccess>
        )) ||
        (processStatus === ProcessStatus.WAITING_CONFIRMATION && (
          <AppLoading message={t("claimingInProgress")} fullHeight={true}>
            <TextSecond size={TextSize.M} color={Color.SECONDARY}>
              {t("tokensClaimed")}
            </TextSecond>

            {hashCreated && <HashCopy hash={hashCreated} />}
            <ButtonStyled
              buttonType={ButtonType.WHITE_BORDER}
              size={ButtonSize.XL}
              onClick={() => {
                hideExchange && hideExchange(false);
                setProcessStatus(ProcessStatus.INITIAL);
              }}
              fullWidth={true}
            >
              <Text
                size={TextSize.L}
                color={Color.SECONDARY}
                weight={TextWeight.BOLD}
              >
                {t("backToWidget")}
              </Text>
            </ButtonStyled>
          </AppLoading>
        )) ||
        (processStatus === ProcessStatus.ERROR && (
          <AppError errorMessage={""} extraMessage={""}>
            <Text size={TextSize.M} color={Color.SECONDARY50}>
              {error}
            </Text>
            <ButtonStyled
              buttonType={ButtonType.WHITE_BORDER}
              size={ButtonSize.XL}
              onClick={() => {
                hideExchange && hideExchange(false);
                setProcessStatus(ProcessStatus.INITIAL);
              }}
              fullWidth={true}
            >
              <Text
                size={TextSize.L}
                color={Color.SECONDARY}
                weight={TextWeight.BOLD}
              >
                {t("backToWidget")}
              </Text>
            </ButtonStyled>
          </AppError>
        ))}
    </>
  );
};
